/*
 * Author M. Atoar Rahman
 * Title: Stock Return Request Approval
 * Description: Index for Stock Return Request Approval
 * Date: 05/06/2022
 */

import { Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import ApprovedTable from './ApprovedTable';
import { useApproval } from './ContextAPI';
import DistributedTable from './DistributedTable';
import FormInfo from './FormInfo';
import PendingTable from './PendingTable';
import ReturnedTable from './ReturnedTable';
import ReturnForm from './ReturnForm';
import ShowModal from './ShowModal';

export default function Index() {
    const { show, showReturn, formShow } = useApproval();
    return formShow ? (
        <FormInfo />
    ) : (
        <>
            {showReturn ? (
                <ReturnForm />
            ) : (
                <Card>
                    <Card.Header className="cardHeader">
                        <h3>Stock Return Request</h3>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Row className="requisition_table">
                                <Col>
                                    <Tabs
                                        defaultActiveKey="pending"
                                        transition={false}
                                        id="noanim-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="pending" title="Pending">
                                            <PendingTable />
                                        </Tab>
                                        <Tab eventKey="approved" title="Approved">
                                            <ApprovedTable />
                                        </Tab>
                                        <Tab eventKey="returned" title="Returned">
                                            <ReturnedTable />
                                        </Tab>
                                        <Tab eventKey="distributed" title="Distributed">
                                            <DistributedTable />
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            )}
            {show && <ShowModal />}
        </>
    );
}

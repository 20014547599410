/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { isEmpty, toArray } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useApproval } from './ContextAPI';
import RequisitionTable from './RequisitionTable';

export default function FormInfo() {
    const {
        setFormShow,
        rowData,
        setRowData,
        receiveData,
        setReceiveData,
        receiveList,
        setReceiveList,
        getPendingData,
        getApprovedData,
    } = useApproval();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        rowData?.records?.map((el) => {
            const itemdata = {};
            itemdata.id = el.id;
            itemdata.drug_id = el.drug_id;
            itemdata.batch_no = el.batch_no;
            itemdata.drug_name = el.drug_name;
            itemdata.approved_qty = el.central_received_qty;

            const list = receiveData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReceiveData(list);

            const arrOfObj = toArray(list);
            setReceiveList(arrOfObj);
            return receiveData;
        });
    }, [rowData.records]);

    const {
        formState: { errors },
        register,
        reset,
        handleSubmit,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        if (!isEmpty(receiveList)) {
            setValue('requisition', receiveList);
        }
    }, [receiveList]);

    const tableDataDisplay = () => <RequisitionTable data={rowData?.records} />;

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            submit_type: 'receive_approved_by_central',
            master_id: rowData.master_id,
            warehouse_id: rowData.warehouse_id,
        };
        try {
            await axiosPrivate.post(
                UrlService.StockReturnRequestApprovalUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.updateNotify('Approved Successfull');
            setFormShow(false);
            reset();
            getPendingData();
            getApprovedData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setFormShow(false);
        setRowData('');
    };

    register('requisition', { required: 'Approve quantity is required!' });

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Stock Return Request Receive Approval</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Table bordered hover size="sm">
                            <thead className="table-light">
                                <tr>
                                    <th>Partner Name</th>
                                    <th>Facility Name</th>
                                    <th>Department Name</th>
                                    <th>Requested Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{rowData?.partner_name}</td>
                                    <td>{rowData?.facility_name}</td>
                                    <td>{rowData?.dispensary_name}</td>

                                    <td>
                                        {rowData?.requested_date
                                            ? moment(rowData?.requested_date).format('DD MMM YYYY')
                                            : ''}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                {tableDataDisplay()}

                <Row>
                    {errors?.requisition && (
                        <span className="invalid">{errors?.requisition?.message}</span>
                    )}
                </Row>

                <div className="formFooter">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className="btnSuccess ms-2"
                        type="button"
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        disabled={isSubmitted}
                    >
                        Approve
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}

/*
 * Author M. Atoar Rahman
 * Title: Internal Request Approval
 * Description: Index for Internal Request Approval
 * Date: 05/06/2022
 */

import { Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { useApproval } from './ContextAPI';
import DistributeApprovedTable from './DistributeApprovedTable';
import DistributePendingTable from './DistributePendingTable';
import FormInfo from './FormInfo';
import ForwardApprovedTable from './ForwardApprovedTable';
import ForwardPendingTable from './ForwardPendingTable';
import ShowModel from './ShowModel';

export default function Index() {
    const { show, formShow } = useApproval();
    return formShow ? (
        <FormInfo />
    ) : (
        <>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Facility Internal Request Approval</h3>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row className="requisition_table">
                            <Col>
                                <Tabs
                                    defaultActiveKey="dpending"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="dpending" title="Distribute (Pending)">
                                        <DistributePendingTable />
                                    </Tab>
                                    <Tab eventKey="dapproved" title="Distribute (Approved)">
                                        <DistributeApprovedTable />
                                    </Tab>
                                    <Tab eventKey="fpending" title="Forward (Pending)">
                                        <ForwardPendingTable />
                                    </Tab>
                                    <Tab eventKey="fapproved" title="Forward (Approved)">
                                        <ForwardApprovedTable />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            {show && <ShowModel />}
        </>
    );
}

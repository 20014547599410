/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { Button, Modal, Row, Table } from 'react-bootstrap';
import { usePoReports } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModel() {
    const { showModal, setShowModal, rowData, setShowPdf, setListShow } = usePoReports();

    // Closing the Model
    const handleClose = () => {
        setShowModal(false);
    };

    const printPreview = () => {
        setListShow(false);
        setShowPdf(true);
        setShowModal(false);
    };

    return (
        <Modal className="modalWidth80" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton className="">
                <Modal.Title>Purchase Order Details</Modal.Title>
                <div style={{ flexGrow: '3' }}>
                    <Button
                        style={{ float: 'right' }}
                        onClick={() => printPreview()}
                        className="btnDark me-1"
                        variant="dark"
                    >
                        Print Preview
                    </Button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>PO No.</th>
                                <th>Delivery Location</th>
                                <th>Delivery Date</th>
                                <th>Supplier Name</th>
                                <th>Supplied Date</th>
                                <th>Received Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{rowData?.purshase_order_no}</td>
                                <td>{rowData?.warehouse_name}</td>
                                <td>
                                    {rowData?.expected_delivery_date
                                        ? moment(rowData?.expected_delivery_date).format(
                                              'DD MMM YYYY'
                                          )
                                        : ''}
                                </td>
                                <td>{rowData?.supplier_name}</td>
                                <td>
                                    {moment(rowData?.purchase_order_supplied_date).format(
                                        'DD MMM YYYY'
                                    )}
                                </td>
                                <td>
                                    {rowData.received_date
                                        ? moment(rowData.received_date).format('DD MMM YYYY')
                                        : ''}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
                <ShowTable data={rowData?.purchased_items} />
            </Modal.Body>
        </Modal>
    );
}

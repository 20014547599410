/*
 * Author M. Atoar Rahman
 * Title: Dispensary Stock Return
 * Description: Page for Dispensary Stock Return
 * Date: 19/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { StockReturnProvider } from '../facility/dispensaryStockReturnReceive/ContextAPI';
import Index from '../facility/dispensaryStockReturnReceive/Index';
import PermissionDenied from './PermissionDenied';

export default function DispensaryStockReturnReceive() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['dispensary-stock-return-receive']) return <PermissionDenied />;

    return (
        <StockReturnProvider>
            <Index />
        </StockReturnProvider>
    );
}

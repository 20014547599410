/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { isEmpty, toArray } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useApproval } from './ContextAPI';
import RequisitionTable from './RequisitionTable';

export default function FormInfo() {
    const {
        setFormShow,
        rowData,
        setRowData,
        receiveData,
        setReceiveData,
        receiveList,
        setReceiveList,
        getPendingData,
        getApprovedData,
    } = useApproval();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        rowData?.records?.map((el) => {
            const itemdata = {};
            itemdata.id = el.id;
            itemdata.drug_id = el.drug_id;
            itemdata.drug_name = el.drug_name;
            itemdata.batch_no = el.batch_no;
            itemdata.approved_qty = el.received_qty;

            const list = receiveData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReceiveData(list);

            const arrOfObj = toArray(list);
            setReceiveList(arrOfObj);
            return receiveData;
        });
    }, [rowData.records]);

    const {
        formState: { errors },
        register,
        reset,
        handleSubmit,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        if (!isEmpty(receiveList)) {
            setValue('requisition', receiveList);
        }
    }, [receiveList]);

    const { drugCategory, getDrugCategory } = CommonApiService();
    // Local State
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <RequisitionTable
                    data={rowData?.records?.filter((el) => el.category_id == item.id)}
                />
            </Tab>
        ));

    // const tableDataDisplay = () => <RequisitionTable data={rowData?.records} />;

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            submit_type: 'facility_received_apv',
            master_id: rowData.master_id,
        };
        try {
            await axiosPrivate.post(
                UrlService.DispencaryStockReturnApprovalUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.updateNotify('Approve Successfull');
            setFormShow(false);
            reset();
            getPendingData();
            getApprovedData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setFormShow(false);
        setRowData('');
    };

    register('requisition', { required: 'Approve quantity is required!' });

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Department Stock Return Receive Approval</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Table bordered hover size="sm">
                            <thead className="table-light">
                                <tr>
                                    <th>Department Name</th>
                                    <th>Requested Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{rowData?.dispensary_name}</td>

                                    <td>
                                        {rowData?.requested_by_date
                                            ? moment(rowData?.requested_by_date).format(
                                                  'DD MMM YYYY'
                                              )
                                            : ''}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Tabs
                        activeKey={page}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 ps-3"
                        // eslint-disable-next-line radix
                        onSelect={(k) => setPage(parseInt(k))}
                    >
                        {tableDataDisplay()}
                    </Tabs>
                </Row>

                <Row>
                    {errors?.requisition && (
                        <span className="invalid">{errors?.requisition?.message}</span>
                    )}
                </Row>

                <div className="formFooter">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="btnInfo me-2 ms-2"
                        variant="primary"
                        style={{ minWidth: '6rem' }}
                        disabled={page === 1}
                        onClick={() => {
                            setPage((currPage) => currPage - 1);
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        type="button"
                        className={`btnSuccess ${page === pageLength && 'd-none'}`}
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        onClick={() => setPage((currPage) => currPage + 1)}
                    >
                        Next
                    </Button>
                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className={`btnSuccess ${page !== pageLength && 'd-none'}`}
                        type="button"
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        disabled={isSubmitted}
                    >
                        Approve
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}

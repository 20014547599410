/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Distribution D2 Table
 * Description: Distribution D2 Table
 * Date: 05/06/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { layout } from '../../SvgIcon';
import { useD2Approval } from './ContextAPI';

export default function ApprovedD2Table() {
    const { setShow, setRowData, d2ApprovedData, getD2ApprovedData, setShowPdf, pdfHandler } =
        useD2Approval();
    const axiosPrivate = useAxiosPrivate();

    const handleShow = async (row) => {
        try {
            const response = await axiosPrivate.get(UrlService.getD2RecordByIdUrl(row.id), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const row_info = {
                d2_master_id: row.id,
                partner_id: row.partner_id,
                partner_name: row.partner_name,
                central_warehouse_id: row.central_warehouse_id,
                month: row.month,
                year: row.year,
                status: row.status,
                records: response?.data?.d2_record_list,
            };
            setRowData(row_info);
            setShow(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const dataList = useCallback(() => {
        getD2ApprovedData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const handlePrint = useCallback(
        (row) => {
            setShowPdf(true);
            pdfHandler(row.id);
        },
        [pdfHandler, setShowPdf]
    );

    const columns = [
        {
            name: 'Partner',
            selector: (row) => row.partner_name,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Month',
            selector: (row) => row.month,
            sortable: true,
            cell: (row) =>
                row.month == 1
                    ? 'January'
                    : row.month == 2
                    ? 'February'
                    : row.month == 3
                    ? 'March'
                    : row.month == 4
                    ? 'April'
                    : row.month == 5
                    ? 'May'
                    : row.month == 6
                    ? 'June'
                    : row.month == 7
                    ? 'July'
                    : row.month == 8
                    ? 'August'
                    : row.month == 9
                    ? 'September'
                    : row.month == 10
                    ? 'October'
                    : row.month == 11
                    ? 'November'
                    : row.month == 12
                    ? 'December'
                    : '',
        },
        {
            name: 'Year',
            selector: (row) => row.year,
            sortable: true,
        },
        {
            name: 'Submitted Date',
            selector: (row) => row.submitted_date,
            format: (row) => moment(row.submitted_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row) =>
                row.status === 'approved' ? (
                    <Badge className="badgeActive" bg="success">
                        Approved
                    </Badge>
                ) : row.status === 'd2_checked' ? (
                    <Badge className="badgeActive" bg="success">
                        D2 Checked
                    </Badge>
                ) : row.status === 'd2_prepared' ? (
                    <Badge className="badgeActive" bg="success">
                        D2 Prepared
                    </Badge>
                ) : row.status === 'final_approved' ? (
                    <Badge className="badgeActive" bg="success">
                        Approved
                    </Badge>
                ) : row.status === 'd2_supplied' ? (
                    <Badge className="badgeActive" bg="success">
                        D2 Supplied
                    </Badge>
                ) : row.status === 'supply_approved' ? (
                    <Badge className="badgeActive" bg="success">
                        Supply Approved
                    </Badge>
                ) : row.status === 'received' ? (
                    <Badge className="badgeActive" bg="success">
                        Received
                    </Badge>
                ) : row.status === 'receive_approved' ? (
                    <Badge className="badgeActive" bg="success">
                        Receive Approved
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    <Button
                        className="btn btn-light actionBtn"
                        variant="primary"
                        onClick={() => handlePrint(row)}
                    >
                        <i className="fa fa-file-pdf" /> Waybill
                    </Button>
                    <Button
                        onClick={() => handleShow(row)}
                        className="btn btn-light actionBtn"
                        type="button"
                        variant="success"
                    >
                        {layout}
                    </Button>
                </>
            ),
        },
    ];

    const search_column = {
        // partner_name: 'Partner',
        // submited_date: 'Submited Date',
    };
    return useDataTable({ columns, data: d2ApprovedData, search_column, pagination: false });
}

import moment from 'moment';
import React from 'react';
import { Badge, Row, Table } from 'react-bootstrap';
import logo from '../../../../assets/unhcr-logo-blue.png';
import { usePoStock } from '../ContextAPI';

function ComponentToPrint(props, ref) {
    const { stockData } = usePoStock();

    console.log(stockData);

    return (
        <fieldset ref={ref} className="customFieldset donation p-5">
            <div className="text-center mb-4">
                <img width="200px" src={logo} alt="" />
            </div>
            <Row>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>
                                <h6 className="mb-0 text-center">
                                    <b>PO Wise Stock Receive Status</b>
                                </h6>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>

            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>PO No.</th>
                            <th>PO Date</th>
                            <th>Number of Items</th>
                            <th>Supplier Name</th>
                            <th>Supplied Qty</th>
                            <th>Received Qty</th>
                            <th>Received Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stockData?.map((el) => (
                            <tr key={Math.random()}>
                                <td>{el.po_no}</td>
                                <td>{el.po_date}</td>
                                <td>
                                    {el.status === 'pending'
                                        ? el.purchase_order_number_of_items
                                        : el.center_stock_in_number_of_items}
                                </td>
                                <td>{el.supplier_name}</td>
                                <td>{el.supply_qty}</td>
                                <td>{el.receive_qty ? el.receive_qty : ''}</td>
                                <td>
                                    {el.status === 'pending'
                                        ? ''
                                        : moment(el.received_date).format('DD MMM YYYY')}
                                </td>
                                <td>
                                    {el.status === 'partial_received' ? (
                                        <Badge className="badgeActive" bg="success">
                                            Partial Received
                                        </Badge>
                                    ) : el.status === 'received' ? (
                                        <Badge className="badgeActive" bg="success">
                                            Received
                                        </Badge>
                                    ) : (
                                        <Badge className="badgeInactive" bg="success">
                                            Pending
                                        </Badge>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;

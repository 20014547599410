/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { isEmpty, toArray } from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useApproval } from './ContextAPI';
import DistributeRequisitionTable from './DistributeRequisitionTable';
import ForwardRequisitionTable from './ForwardRequisitionTable';

export default function FormInfo() {
    const {
        setFormShow,
        rowData,
        setRowData,
        receiveData,
        setReceiveData,
        receiveList,
        setReceiveList,
        forwardData,
        setForwardData,
        forwardList,
        setForwardList,
        getPendingForwardData,
        getApprovedForwardData,
        getPendingDistributeData,
        getApprovedDistributeData,
    } = useApproval();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        rowData?.records?.map((el) => {
            const itemdata = {};
            itemdata.id = el.id;
            itemdata.drug_id = el.drug_id;
            itemdata.drug_name = el.drug_name;
            itemdata.category_id = el.category_id;
            itemdata.partner_available_qty = el.partner_available_qty;
            itemdata.facility_approved_qty = el.facility_approved_qty;

            itemdata.partner_distribute_qty = el.partner_distribute_qty;
            itemdata.partner_forward_qty = el.partner_forward_qty;

            const list = receiveData;
            const forward_list = forwardData;

            if (el.status == 'distributed') {
                itemdata.approved_supply_qty = !Number(el.partner_available_qty)
                    ? 0
                    : Number(el.partner_available_qty) > Number(el.partner_distribute_qty)
                    ? el.partner_distribute_qty
                    : el.partner_available_qty;

                list[itemdata.drug_id] = itemdata;
            }

            if (el.status == 'forwarded') {
                itemdata.approved_supply_qty = el.partner_forward_qty;

                forward_list[itemdata.drug_id] = itemdata;
            }

            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
                delete forward_list[itemdata.drug_id];
            }

            setReceiveData(list);
            setForwardData(forward_list);

            const arrOfObj = toArray(list);
            setReceiveList(arrOfObj);

            const arrOfObjforward = toArray(forward_list);
            setForwardList(arrOfObjforward);

            return receiveData;
        });
    }, [rowData.records]);

    // console.log(receiveData);

    const { register, reset, handleSubmit, setValue } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        if (!isEmpty(receiveList) && rowData.status == 'partner_distribute_apv') {
            setValue('requisition', receiveList);
        }
    }, [receiveList]);

    useEffect(() => {
        if (!isEmpty(forwardList) && rowData.status == 'partner_forward_apv') {
            setValue('requisition', forwardList);
        }
    }, [forwardList]);

    const tableDataDisplayDistribute = () => <DistributeRequisitionTable data={rowData?.records} />;
    const tableDataDisplayForward = () => <ForwardRequisitionTable data={rowData?.records} />;

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            submit_type: rowData.status,
            master_id: rowData.master_id,
        };
        try {
            await axiosPrivate.post(
                UrlService.internalRequestApprovalUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.updateNotify('Approve Successfull');
            setFormShow(false);
            reset();
            getPendingForwardData();
            getApprovedForwardData();
            getPendingDistributeData();
            getApprovedDistributeData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setFormShow(false);
        setRowData('');
    };

    register('requisition', { required: 'Requisition quantity is required!' });

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Facility Internal Request Approval</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                {rowData.status == 'partner_distribute_apv' && tableDataDisplayDistribute()}
                {rowData.status == 'partner_forward_apv' && tableDataDisplayForward()}

                <div className="formFooter">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className="btnSuccess ms-2"
                        type="button"
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        disabled={isSubmitted}
                    >
                        Approve
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}

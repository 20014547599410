/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useApproval } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModel() {
    const { show, setShow, rowData } = useApproval();

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };

    const tableDataDisplayDistribute = () => (
        <ShowTable data={rowData?.records?.filter((el) => el.status == 'distributed')} />
    );

    const tableDataDisplayForword = () => (
        <ShowTable data={rowData?.records?.filter((el) => el.status == 'forwarded')} />
    );

    return (
        <Modal className="modalWidth80" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Internal Request Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Table bordered hover size="sm">
                                <thead className="table-light">
                                    <tr>
                                        <th>Facility Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{rowData?.facility_name}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Tabs
                        defaultActiveKey="distribute"
                        id="tab-internal-request"
                        className="tab-internal-request p-2 pb-0"
                        style={{ background: '#eee' }}
                    >
                        <Tab eventKey="distribute" title="Distribute to Facility">
                            {tableDataDisplayDistribute()}
                        </Tab>
                        <Tab eventKey="forward" title="Forward to UNHCR">
                            {tableDataDisplayForword()}
                        </Tab>
                    </Tabs>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

/* eslint-disable eqeqeq */
import moment from 'moment';
import React from 'react';
import { Badge, Col, Row, Table } from 'react-bootstrap';
import logo from '../../../../assets/unhcr-logo-blue.png';
import { usePoReports } from '../ContextAPI';

function ComponentToPrint(props, ref) {
    const { data, rowData, dateRange } = usePoReports();

    return !rowData ? (
        <fieldset ref={ref} className="customFieldset donation p-5">
            <div className="text-center mb-4">
                <img className="mb-3" width="200px" src={logo} alt="" />
                <br />
                <h5 className="d-block text-decoration-underline">Purchase Order Reports</h5>
            </div>

            {dateRange?.start_date && dateRange?.end_date && (
                <Row>
                    <Col lg={6} />
                    <Col lg={6} className="text-end">
                        <p className="mb-0">
                            <b>Date from:</b>{' '}
                            {dateRange.start_date
                                ? moment(dateRange.start_date).format('DD MMM YYYY')
                                : ''}
                            {', '}
                            <b>Date to:</b>{' '}
                            {dateRange.end_date
                                ? moment(dateRange.end_date).format('DD MMM YYYY')
                                : ''}
                        </p>
                    </Col>
                </Row>
            )}

            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>PO No</th>
                            <th>PO Date</th>
                            <th>Supplier Name</th>
                            <th>Supplied Date</th>
                            <th>Warehouse Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((el) => (
                            <tr key={Math.random()}>
                                <td>{el?.purshase_order_no}</td>
                                <td>{el?.purshase_order_date}</td>
                                <td>{el?.supplier_name}</td>
                                <td>{el?.purchase_order_supplied_date}</td>
                                <td>{el?.warehouse_name}</td>
                                <td>
                                    {el.purchase_order_status == 'received' ? (
                                        <Badge className="badgeActive" bg="success">
                                            Received
                                        </Badge>
                                    ) : (
                                        <Badge className="badgeInactive" bg="success">
                                            Pending
                                        </Badge>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
        </fieldset>
    ) : (
        <fieldset ref={ref} className="customFieldset donation p-5">
            <div className="text-center mb-4">
                <img className="mb-3" width="200px" src={logo} alt="" />
                <br />
                <h5 className="d-block text-decoration-underline">Purchase Order Report Details</h5>
            </div>

            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>PO No.</th>
                            <th>Delivery Location</th>
                            <th>Delivery Date</th>
                            <th>Supplier Name</th>
                            <th>Supplied Date</th>
                            <th>Received Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{rowData?.purshase_order_no}</td>
                            <td>{rowData?.warehouse_name}</td>
                            <td>
                                {rowData?.expected_delivery_date
                                    ? moment(rowData?.expected_delivery_date).format('DD MMM YYYY')
                                    : ''}
                            </td>
                            <td>{rowData?.supplier_name}</td>
                            <td>
                                {moment(rowData?.purchase_order_supplied_date).format(
                                    'DD MMM YYYY'
                                )}
                            </td>
                            <td>
                                {rowData.received_date
                                    ? moment(rowData.received_date).format('DD MMM YYYY')
                                    : ''}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>Purchase Qty</th>
                            <th>Received Qty</th>
                            <th>Rejected Qty</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rowData?.purchased_items?.map((el) => (
                            <tr key={Math.random()}>
                                <td>{el?.drug_name}</td>
                                <td>{el?.purchase_qty}</td>
                                <td>{el?.received_qty}</td>
                                <td>{el?.rejected_qty}</td>
                                <td>
                                    {el.status == 'received' ? (
                                        <Badge className="badgeActive" bg="success">
                                            Received
                                        </Badge>
                                    ) : el.status == 'partial_received' ? (
                                        <Badge className="badgeInactive" bg="success">
                                            Partial Received
                                        </Badge>
                                    ) : (
                                        <Badge className="badgeInactive" bg="success">
                                            Pending
                                        </Badge>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;

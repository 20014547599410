/*
 * Author M. Atoar Rahman
 * Title: Partner Internal Approve
 * Description: Page for Partner Internal Approve
 * Date: 28/04/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { PartnerInternalRequestApproveProvider } from '../partner/internalRequestApprove/ContextAPI';
import Index from '../partner/internalRequestApprove/Index';
import PermissionDenied from './PermissionDenied';

export default function PartnerInternalRequestApprove() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['partner-internal-request-approval']) return <PermissionDenied />;

    return (
        <PartnerInternalRequestApproveProvider>
            <Index />
        </PartnerInternalRequestApproveProvider>
    );
}

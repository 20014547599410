/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import CommonApiService from '../../../services/CommonApiService';
import { useStockReturn } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModal() {
    const { showModal, setShowModal, rowData } = useStockReturn();

    const { drugCategory, getDrugCategory } = CommonApiService();

    // Local State
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <ShowTable data={rowData?.records?.filter((el) => el.category_id == item.id)} />
            </Tab>
        ));

    // Closing the Model
    const handleClose = () => {
        setShowModal(false);
    };

    console.log(rowData);

    return (
        <Modal className="modalWidth80" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Stock Return Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Table bordered hover size="sm">
                                <thead className="table-light">
                                    <tr>
                                        <th>Partner Name</th>
                                        <th>Warehouse Name</th>
                                        <th>Requested Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{rowData?.partner_name}</td>
                                        <td>{rowData?.warehouse_name}</td>
                                        <td>{rowData?.requested_by_date}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Tabs
                            activeKey={page}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3 ps-3"
                            // eslint-disable-next-line radix
                            onSelect={(k) => setPage(parseInt(k))}
                        >
                            {tableDataDisplay()}
                        </Tabs>
                    </Row>
                    <div className="formFooter">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className="btnInfo me-2 ms-2"
                            variant="primary"
                            style={{ minWidth: '6rem' }}
                            disabled={page === 1}
                            onClick={() => {
                                setPage((currPage) => currPage - 1);
                            }}
                        >
                            Prev
                        </Button>
                        <Button
                            type="button"
                            className="btnSuccess"
                            variant="success"
                            style={{ minWidth: '6rem' }}
                            onClick={() => setPage((currPage) => currPage + 1)}
                            disabled={page === pageLength}
                        >
                            Next
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

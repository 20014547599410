/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import useDataTable from '../../../hooks/useDataTable';

export default function PendingDetailsDataTable({ data }) {
    const columns = [
        {
            name: 'Item Names',
            selector: (row) =>
                `${row.code_no} ${row.drug_name} ${row.presentation_types} ${row.presentation_strengths}`,
            sortable: true,
            wrap: true,
        },
        {
            name: 'PO No',
            selector: (row) => row.purshase_order_no,
            sortable: true,
        },
        {
            name: 'PO Date',
            selector: (row) => row.purchase_order_supplied_date,
            format: (row) =>
                row.purchase_order_supplied_date
                    ? moment(row.purchase_order_supplied_date).format('DD MMM YYYY')
                    : '',
            sortable: true,
        },
        {
            name: 'Purchase Qty',
            selector: (row) => row.purchase_qty,
            sortable: true,
        },
    ];

    const search_column = {
        // code_no: 'UID',
        // drug_name: 'Item Names',
        // presentation_strengths: 'Presentation Strengths',
        // presentation_types: 'Presentation Types',
        // purshase_order_no: 'PO No',
        // purchase_order_supplied_date: 'PO Date',
        // purchase_qty: 'Purchase Qty',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Internal Request DataTable
 * Description: Dispensary Internal Request DataTable
 * Date: 09/06/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useInternalRequest } from './ContextAPI';
// import SweetAlertService from '../../../services/SweetAlertService';
// import UrlService from '../../../services/UrlService';
import { layout } from '../../SvgIcon';

export default function DataTable() {
    const { internalRequests, getAllData, setShowModal, setRowData } = useInternalRequest();
    // const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const showHander = (row) => {
        setShowModal(true);
        setRowData(row);
    };

    const columns = [
        {
            name: 'Partner',
            selector: (row) => row.partner_name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Facility',
            selector: (row) => row.facility_name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Expected Receive Date',
            selector: (row) => row.expected_receive_date,
            format: (row) =>
                row.expected_receive_date
                    ? moment(row.expected_receive_date).format('DD MMM YYYY')
                    : '',
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row) =>
                row.status === 'partner_distributed' ? (
                    <Badge className="badgeActive" bg="success">
                        Partner Distributing
                    </Badge>
                ) : row.status === 'partner_distribute_apv' ? (
                    <Badge className="badgeActive" bg="success">
                        Partner Distributed
                    </Badge>
                ) : row.status === 'partner_forwarded' ? (
                    <Badge className="badgeActive" bg="success">
                        Partner Forwarding
                    </Badge>
                ) : row.status === 'partner_forward_apv' ? (
                    <Badge className="badgeActive" bg="success">
                        Partner Forwarded
                    </Badge>
                ) : row.status === 'unhcr_received' ? (
                    <Badge className="badgeActive" bg="success">
                        UNHCR Received
                    </Badge>
                ) : row.status === 'unhcr_approved' ? (
                    <Badge className="badgeActive" bg="success">
                        UNHCR Approved
                    </Badge>
                ) : row.status === 'center_received' ? (
                    <Badge className="badgeActive" bg="success">
                        Central Received
                    </Badge>
                ) : row.status === 'center_distributed' ? (
                    <Badge className="badgeActive" bg="success">
                        Central Distributed
                    </Badge>
                ) : row.status === 'partner_received' ? (
                    <Badge className="badgeActive" bg="success">
                        Partner Receiving
                    </Badge>
                ) : row.status === 'partner_receive_apv' ? (
                    <Badge className="badgeActive" bg="success">
                        Partner Received
                    </Badge>
                ) : row.status === 'facility_received' ? (
                    <Badge className="badgeActive" bg="success">
                        Facility Receiving
                    </Badge>
                ) : row.status === 'facility_receive_apv' ? (
                    <Badge className="badgeActive" bg="success">
                        Facility Received
                    </Badge>
                ) : row.status === 'approved' ? (
                    <Badge className="badgeActive" bg="success">
                        Approved
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    className="btn btn-light actionBtn"
                    variant="primary"
                    onClick={() => showHander(row)}
                >
                    {layout}
                </Button>
            ),
        },
    ];

    return useDataTable({ columns, data: internalRequests });
}

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Stock Reigister
 * Description: Stock Reigister
 * Date: 15/07/2022
 */

import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useStockRegister } from './ContextAPI';

export default function FormInfo() {
    const { setShowBin, setPdfData } = useStockRegister();
    const axiosPrivate = useAxiosPrivate();

    const [drugData, setDrugData] = useState();
    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const { partnerStockRecordMdicineList, getPartnerStockRecordMdicineList } = CommonApiService();

    const dataList = useCallback(() => {
        getPartnerStockRecordMdicineList(); //  DrugInfo
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        // Drug Info
        setDrugData(
            partnerStockRecordMdicineList?.map((el) => ({
                value: el.drug_id,
                label: el.drug_name,
            }))
        );
    }, [partnerStockRecordMdicineList]);

    const formSubmitHandler = async (data) => {
        const formData = {
            ...data,
            drug_id: data?.drug_id?.value,
            date_from: data?.date_from?.toLocaleDateString('sv-SE'),
            date_to: data?.date_to?.toLocaleDateString('sv-SE'),
        };

        // console.log(formData);

        try {
            const response = await axiosPrivate.post(
                UrlService.partnerStockRegBinCardUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!isEmpty(response.data.stock_records?.stock_list)) {
                setPdfData(response.data.stock_records);
                setShowBin(true);
            } else {
                ToastifyService.errorNotify('Data not found!');
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h6>Stock Card</h6>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Form>
                        <Row>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Item Name</Form.Label>
                                    <Controller
                                        name="drug_id"
                                        id="drug_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={errors?.drug_id && 'invalidInput'}
                                                options={drugData}
                                            />
                                        )}
                                        rules={{ required: 'Please select your item.' }}
                                    />
                                    {errors.drug_id && (
                                        <span className="invalid">{errors.drug_id?.message}</span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date From</Form.Label>
                                    <Controller
                                        control={control}
                                        name="date_from"
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                closeOnScroll
                                                placeholderText="Select date"
                                                dateFormat="dd-MM-yyyy"
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                className="form-control"
                                                popperPlacement="top-end"
                                                // minDate={new Date()}
                                            />
                                        )}
                                        rules={{
                                            required: 'Date is required.',
                                            valueAsDate: true,
                                        }}
                                    />
                                    {errors.date_from && (
                                        <span className="invalid">{errors.date_from?.message}</span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date To</Form.Label>
                                    <Controller
                                        control={control}
                                        name="date_to"
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                closeOnScroll
                                                placeholderText="Select date"
                                                dateFormat="dd-MM-yyyy"
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                className="form-control"
                                                popperPlacement="top-end"
                                                // minDate={new Date()}
                                            />
                                        )}
                                        rules={{
                                            required: 'Date is required.',
                                            valueAsDate: true,
                                        }}
                                    />
                                    {errors.date_to && (
                                        <span className="invalid">{errors.date_to?.message}</span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={2} className="mt-3">
                                <Button
                                    className="btnSuccess mt-1"
                                    onClick={handleSubmit(formSubmitHandler)}
                                    type="button"
                                    variant="success"
                                >
                                    Search <i className="fa fa-search" />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Card.Body>
        </Card>
    );
}

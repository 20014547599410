/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { Col, Modal, Row } from 'react-bootstrap';
import { usePurchaseOrder } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModel() {
    const { showModal, setShowModal, rowData } = usePurchaseOrder();

    // Closing the Model
    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <Modal className="modalWidth80" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Purchase Order Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Col>
                        <h7>
                            <b>PO No: </b> {rowData.purshase_order_no}
                        </h7>
                        <br />
                        <h7>
                            <b>Delivery Location: </b> {rowData.warehouse_name}
                        </h7>
                    </Col>
                    <Col>
                        <h7>
                            <b>Supplier Name: </b> {rowData.supplier_name}
                        </h7>
                        <br />
                        <h7>
                            <b>Delivery Date: </b>{' '}
                            {rowData.expected_delivery_date
                                ? moment(rowData.expected_delivery_date).format('DD MMM YYYY')
                                : ''}
                        </h7>
                    </Col>
                    <Col>
                        <h7>
                            <b>Supplied Date: </b>{' '}
                            {moment(rowData.purchase_order_supplied_date).format('DD MMM YYYY')}
                        </h7>
                        <br />
                        <h7>
                            <b>Received Date: </b>{' '}
                            {rowData.received_date
                                ? moment(rowData.received_date).format('DD MMM YYYY')
                                : ''}
                        </h7>
                    </Col>
                </Row>
                <ShowTable data={rowData.purchased_items} />
            </Modal.Body>
        </Modal>
    );
}

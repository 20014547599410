/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Contex API
 * Description: Contex API
 * Date: 15/07/2022
 */

import { createContext, useContext, useState } from 'react';

// Create Context
const ContextAPI = createContext();

export function usePoStock() {
    return useContext(ContextAPI);
}

// Provider Component
export function PoStockProvider({ children }) {
    const [show, setShow] = useState(false);
    const [pendingModalShow, setPendingModalShow] = useState(false);
    const [stockData, setStockData] = useState();
    const [rowData, setRowData] = useState();

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                pendingModalShow,
                setPendingModalShow,
                stockData,
                setStockData,
                rowData,
                setRowData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Drug DataTable
 * Description: Drug DataTable
 * Date: 26/04/2022
 */

import { useCallback, useEffect } from 'react';
import Barcode from 'react-barcode';
import { Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import SweetAlertService from '../../../services/SweetAlertService';
import UrlService from '../../../services/UrlService';
import { useDrug } from './ContextAPI';

export default function DataTable() {
    const { data, getAllData, setShow, setRowData, setIsSubmitted } = useDrug();
    const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
        setRowData('');
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const editHander = (row) => {
        setShow(true);
        setRowData(row);
        setIsSubmitted(false);
    };

    const deleteHandler = (row) => {
        deleteSwal({
            id: row.drug_id,
            url: UrlService.deleteDrugUrl(),
            refreshList: getAllData,
            beforeTitle: 'Are you sure?',
            beforeText: "You won't be able to revert this!",
            afterTitle: 'Deleted!',
            afterText: 'Your file has been deleted.',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    };

    const columns = [
        {
            name: 'UID',
            selector: (row) => row.code_no,
            sortable: true,
        },
        {
            name: 'Barcode',
            selector: (row) => row.code_no,
            cell: (row) => (
                <Barcode height="30" displayValue value={row.code_no} format="CODE128" />
            ),
        },
        {
            name: 'Generic Name',
            selector: (row) => row.generic_name,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Administration Route',
            selector: (row) => row.group_name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Category',
            selector: (row) => row.category_name,
            sortable: true,
        },
        {
            name: 'Presentation Strength',
            selector: (row) => row.strength_name,
            sortable: true,
        },
        {
            name: 'Presentation Type',
            selector: (row) => row.ptype,
            sortable: true,
        },

        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    {/* <Button data-id={row.id} type="button" className="btn btn-light actionBtn">
                        {layout}
                    </Button> */}

                    {/* <FormInfo editData={row} /> */}

                    <Button
                        className="btn btn-light actionBtn"
                        variant="primary"
                        onClick={() => editHander(row)}
                    >
                        <i className="fas fa-edit" />
                    </Button>

                    <Button
                        onClick={() => {
                            deleteHandler(row);
                        }}
                        type="button"
                        className="btn btn-light actionBtn"
                    >
                        <i className="fas fa-trash-alt" />
                    </Button>
                </>
            ),
        },
    ];

    const search_column = {
        code_no: 'UID',
        generic_name: 'Generic Name',
        group_name: 'Administration Route',
        category_name: 'Category',
        strength_name: 'Presentation Strength',
        ptype: 'Presentation Type',
    };
    return useDataTable({ columns, data, search_column });
}

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import CommonApiService from '../../../services/CommonApiService';
import { usePoStock } from './ContextAPI';
import PendingModelData from './PendingModelData';

export default function PendingShowModel() {
    const { pendingModalShow, setPendingModalShow, rowData } = usePoStock();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    // Closing the Model
    const handleClose = () => {
        setPendingModalShow(false);
    };

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <PendingModelData data={rowData?.filter((el) => el.category_id == item.id)} />
            </Tab>
        ));

    return (
        <Modal className="modalWidth80" show={pendingModalShow} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Stock received (%) as per pipeline</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Tabs
                            activeKey={page}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            // eslint-disable-next-line radix
                            onSelect={(k) => setPage(parseInt(k))}
                        >
                            {tableDataDisplay()}
                        </Tabs>
                    </Row>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className="btnInfo me-2 ms-2"
                            variant="primary"
                            style={{ minWidth: '6rem' }}
                            disabled={page === 1}
                            onClick={() => {
                                setPage((currPage) => currPage - 1);
                            }}
                        >
                            Prev
                        </Button>
                        <Button
                            type="button"
                            className="btnSuccess"
                            disabled={page === pageLength}
                            variant="success"
                            style={{ minWidth: '6rem' }}
                            onClick={() => setPage((currPage) => currPage + 1)}
                        >
                            Next
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Contex API
 * Description: Contex API
 * Date: 15/07/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Create Context
const ContextAPI = createContext();

export function useStockReturnRequest() {
    return useContext(ContextAPI);
}

// Provider Component
export function StockReturnRequestProvider({ children }) {
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showList, setListShow] = useState(true);
    const [rowData, setRowData] = useState();
    const [modalData, setModalData] = useState({});
    const [firstSubmitInfo, setFirstSubmitInfo] = useState();
    const [returnData, setReturnData] = useState({});
    const [returnList, setReturnList] = useState({});

    const [pendingData, setPendingData] = useState();
    const [approvedData, setApprovedData] = useState();

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data  All Data
    const getPendingData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllStockReturnRrequestUrl('request_by_unhcr', 0),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setPendingData(response?.data?.stock_return_request_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getApprovedData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllStockReturnRrequestUrl('request_approved_by_unhcr', 0),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setApprovedData(response?.data?.stock_return_request_info);
            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                showList,
                setListShow,
                rowData,
                setRowData,
                returnData,
                setReturnData,
                returnList,
                setReturnList,
                firstSubmitInfo,
                setFirstSubmitInfo,
                modalData,
                setModalData,
                showModal,
                setShowModal,
                pendingData,
                getPendingData,
                approvedData,
                getApprovedData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;

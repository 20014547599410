/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Bin Card
 * Description: Bin Card
 * Date: 15/07/2022
 */

import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useBinCard } from './ContextAPI';

export default function FormInfo() {
    const { setShowBin, setPdfData } = useBinCard();
    const axiosPrivate = useAxiosPrivate();

    const [drugData, setDrugData] = useState();
    const [batchNoInfo, setBatchNoInfo] = useState();
    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const { partnerStockRecordMdicineList, getPartnerStockRecordMdicineList } = CommonApiService();

    const dataList = useCallback(() => {
        getPartnerStockRecordMdicineList(); //  DrugInfo
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        // Drug Info
        setDrugData(
            partnerStockRecordMdicineList?.map((el) => ({
                value: el.drug_id,
                label: el.drug_name,
            }))
        );
    }, [partnerStockRecordMdicineList]);

    const batchNoHandler = async (drugId) => {
        try {
            const response = await axiosPrivate.get(UrlService.partnerBatchNoByIdUrl(drugId), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setBatchNoInfo(
                response?.data?.stock_records?.map((el) => ({
                    value: el.batch_no,
                    label: el.batch_no,
                }))
            );
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    const formSubmitHandler = async (data) => {
        const formData = {
            ...data,
            drug_id: data?.drug_id?.value,
            batch_no: data?.batch_no?.value,
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.partnerStockRegBinCardUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!isEmpty(response.data.stock_records?.stock_list)) {
                setPdfData(response?.data?.stock_records);
                setShowBin(true);
            } else {
                ToastifyService.errorNotify('Data not found!');
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h6>Stock Record Card/BIN Card</h6>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Form>
                        <Row>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Item Name</Form.Label>
                                    <Controller
                                        name="drug_id"
                                        id="drug_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={errors?.drug_id && 'invalidInput'}
                                                options={drugData}
                                                onBlur={() => batchNoHandler(field?.value?.value)}
                                            />
                                        )}
                                        rules={{ required: 'Please select your item.' }}
                                    />
                                    {errors.drug_id && (
                                        <span className="invalid">{errors.drug_id?.message}</span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Batch No</Form.Label>
                                    <Controller
                                        name="batch_no"
                                        id="batch_no"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={errors?.batch_no && 'invalidInput'}
                                                options={batchNoInfo}
                                            />
                                        )}
                                        rules={{ required: 'Field is required.' }}
                                    />
                                    {errors.batch_no && (
                                        <span className="invalid">{errors.batch_no?.message}</span>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col lg={2} className="mt-3">
                                <Button
                                    className="btnSuccess mt-1"
                                    onClick={handleSubmit(formSubmitHandler)}
                                    type="button"
                                    variant="success"
                                >
                                    Search <i className="fa fa-search" />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Card.Body>
        </Card>
    );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Badge } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';

export default function ShowTable({ data }) {
    // const conditionalCellStyles = [
    //     {
    //         when: (row) => Number(row.partner_available_qty) <= 0,
    //         style: {
    //             backgroundColor: '#ffb4c1',
    //         },
    //     },
    // ];

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            width: '250px',
            fixed: true,
            // conditionalCellStyles,
        },
        {
            name: 'Category Name',
            selector: (row) => row.category_name,
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row) =>
                row.status === 'distributed' ? (
                    <Badge className="badgeActive" bg="success">
                        Distributed
                    </Badge>
                ) : row.status === 'forwarded' ? (
                    <Badge className="badgeActive" bg="success">
                        Forwarded
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Facility Requested Qty',
            selector: (row) => row.facility_approved_qty,
            sortable: true,
        },
        {
            name: 'Partner Requested Qty',
            selector: (row) => row.partner_forward_apv_qty,
            sortable: true,
        },
        {
            name: 'Partner Distribute Qty',
            selector: (row) => row.partner_distribute_apv_qty,
            sortable: true,
        },
        {
            name: 'Facility Received Qty',
            selector: (row) => row.facility_received_qty,
            sortable: true,
        },
        {
            name: 'Facility Rejected Qty',
            selector: (row) => row.facility_rejected_qty,
            sortable: true,
        },
        {
            name: 'Facility Rejected Reason',
            selector: (row) => row.facility_reject_reason,
            sortable: true,
        },
    ];

    const search_column = {
        // drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}

/*
 * Author M. Atoar Rahman
 * Title: Internal Request Approval
 * Description: Index for Internal Request Approval
 * Date: 05/06/2022
 */

import { Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import ApprovedTable from './ApprovedTable';
import { useApproval } from './ContextAPI';
import FormInfo from './FormInfo';
import PendingTable from './PendingTable';
import ShowModel from './ShowModel';

export default function Index() {
    const { show, formShow } = useApproval();
    return formShow ? (
        <FormInfo />
    ) : (
        <>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Internal Request Receive</h3>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row className="requisition_table">
                            <Col>
                                <Tabs
                                    defaultActiveKey="pending"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="pending" title="Pending">
                                        <PendingTable />
                                    </Tab>
                                    <Tab eventKey="approved" title="Approved">
                                        <ApprovedTable />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            {show && <ShowModel />}
        </>
    );
}

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Row, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useApproval } from './ContextAPI';
import ReturnData from './ReturnData';

export default function FormInfo() {
    const {
        setShowReturn,
        rowData,
        getPendingData,
        getApprovedData,
        getReturnedData,
        getDistributedData,
    } = useApproval();
    const { drugCategory, getDrugCategory } = CommonApiService();

    // Local State

    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const {
        formState: { errors },

        handleSubmit,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <ReturnData data={rowData?.records?.filter((el) => el.category_id == item.id)} />
            </Tab>
        ));

    // Form Submit Handelar
    const formSubmitHandler = async () => {
        setIsSubmitted(true);

        const formData = {
            submit_type: 'return_approving_by_partner',
            master_id: rowData.master_id,
        };
        try {
            await axiosPrivate.post(
                UrlService.StockReturnRequestApprovalUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.updateNotify('Approve Successfull');
            setShowReturn(false);
            getPendingData();
            getApprovedData();
            getReturnedData();
            getDistributedData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setShowReturn(false);
    };

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Stock Return Request Approval</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Tabs
                        activeKey={page}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 ps-3"
                        // eslint-disable-next-line radix
                        onSelect={(k) => setPage(parseInt(k))}
                    >
                        {tableDataDisplay()}
                    </Tabs>
                </Row>
                <Row>
                    {errors?.itemDetails && (
                        <span className="invalid">{errors?.itemDetails?.message}</span>
                    )}
                </Row>

                <div className="formFooter">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="btnInfo me-2 ms-2"
                        variant="primary"
                        style={{ minWidth: '6rem' }}
                        disabled={page === 1}
                        onClick={() => {
                            setPage((currPage) => currPage - 1);
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        type="button"
                        className={`btnSuccess ${page === pageLength && 'd-none'}`}
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        onClick={() => setPage((currPage) => currPage + 1)}
                    >
                        Next
                    </Button>

                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className={`btnSuccess ${page !== pageLength && 'd-none'}`}
                        type="button"
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        disabled={isSubmitted}
                    >
                        Approve
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}

/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Facility Stock Return
 * Description: Facility Stock Return
 * Date: 19/06/2022
 */

import { createContext, useContext, useReducer, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Initial State
const initialState = {
    stockReturn: [],
};

// Reducer Function
const reducer = (state, action) => {
    switch (action.type) {
        case 'LIST_DATA':
            return {
                ...state,
                stockReturn: action.payload,
            };
        case 'ADD_REQ':
            return {
                ...state,
                stockReturn: [action.payload, ...state.stockReturn],
            };
        case 'EDIT_REQ':
            const actionPayload = action.payload;

            const updateStockReturn = state.stockReturn.map((el) => {
                if (el.id === actionPayload.id) {
                    return actionPayload;
                }
                return el;
            });
            return {
                ...state,
                stockReturn: updateStockReturn,
            };

        default:
            return state;
    }
};

// Create Context
const ContextAPI = createContext(initialState);

export function useStockReturn() {
    return useContext(ContextAPI);
}

// Provider Component
export function FacilityStockReturnProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [rowData, setRowData] = useState({});

    const [pendingData, setPendingData] = useState();
    const [approvedData, setApprovedData] = useState();

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data  All Data
    const getPendingData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllFacilityStockReturnUrl('pending', 0),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setPendingData(response?.data?.stock_return_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getApprovedData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllFacilityStockReturnUrl('approved', 0),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setApprovedData(response?.data?.stock_return_info);
            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Add/Edit Data
    const addOrUodateData = async (data, id) => {
        try {
            await axiosPrivate.post(
                id
                    ? UrlService.updateFacilityStockReturnUrl(id)
                    : UrlService.saveFacilityStockReturnUrl(),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (id != null) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
            }
            // await getAllData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else if (id) {
                console.log('Data Update Failed!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    // Add Stock Return
    const addStockReturn = (data) => {
        addOrUodateData(data);
        dispatch({
            type: 'ADD_REQ',
            payload: data,
        });
    };

    // Edit Stock Return
    const editStockReturn = (data, id) => {
        addOrUodateData(data, id);
        dispatch({
            type: 'EDIT_REQ',
            payload: data,
        });
    };

    return (
        <ContextAPI.Provider
            value={{
                stockReturn: state.stockReturn,
                // getAllData,
                addStockReturn,
                editStockReturn,
                show,
                setShow,
                showModal,
                setShowModal,
                rowData,
                setRowData,
                pendingData,
                getPendingData,
                approvedData,
                getApprovedData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;

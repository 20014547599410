/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Hook: DataTable
 * Description: Hook: DataTable
 * Date: 15/03/2022
 */

import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Loader from '../components/Loader';

function FilterComponent({ filterText, onFilter, onClear, showSearchClear }) {
    return (
        <div className="d-flex searchBox">
            <Form.Control
                type="text"
                placeholder="Filter Table"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
            <div className="search-icon">
                <i className="fas fa-search" />
            </div>

            {showSearchClear && (
                <Button className="btn btn-light searchClearBtn" type="button" onClick={onClear}>
                    x
                </Button>
            )}
        </div>
    );
}

const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'ALL',
};

const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
        <input
            htmlFor="booty-check"
            type="checkbox"
            className="form-check-input"
            ref={ref}
            onClick={onClick}
            {...rest}
        />
    </div>
));

export default function useDataTable(tableData) {
    const {
        columns,
        data,
        pagination,
        search_column,
        expandableRows,
        expandedComponent,
        conditionalRowStyles,
        cellPadding,
        borderWidth,
    } = tableData;

    const searchColumn = search_column || {};

    const [filterText, setFilterText] = useState('');
    const [pending, setPending] = useState(true);
    const [dataList, setDataList] = useState([]);
    const [showSearchClear, setShowSearchClear] = useState(false);

    useEffect(() => {
        if (!isEmpty(data)) {
            setDataList(data);
            setPending(false);
        }
        const timeout = setTimeout(() => {
            if (isEmpty(data)) {
                setDataList([]);
                setPending(false);
            }
        }, 1500);
        return () => clearTimeout(timeout);
    }, [data]);

    // Start Export CSV
    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(searchColumn);
        const values = Object.values(searchColumn);
        result = '';
        result += values.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach((item) => {
            let ctr = 0;
            keys.forEach((key) => {
                if (ctr > 0) result += columnDelimiter;
                if (key.includes('.')) {
                    const subArr = key.split('.');
                    const subArrString =
                        item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]]?.[subArr[4]] ??
                        item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]] ??
                        item[subArr[0]]?.[subArr[1]]?.[subArr[2]] ??
                        item[subArr[0]]?.[subArr[1]];

                    result += `"${subArrString}"`;
                } else {
                    result += `"${item[key]}"`;
                }
                // eslint-disable-next-line no-plusplus
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
        const filename = 'export.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const isDownloadCsv = Object.keys(searchColumn).length;

    function Export({ onExport }) {
        return (
            isDownloadCsv > 0 && (
                <Button
                    className="btn btn-light downloadCsvBtn"
                    onClick={(e) => onExport(e.target.value)}
                >
                    <i className="fa fa-download" />
                </Button>
            )
        );
    }
    // End Export CSV

    // Start Filter Text
    const filteredItems = dataList.filter((item) => {
        const searchResult = Object.keys(searchColumn).map((el) => {
            // If relational data
            if (el.includes('.')) {
                const subArr = el.split('.');
                const subArrString =
                    item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]]?.[subArr[4]] ??
                    item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]] ??
                    item[subArr[0]]?.[subArr[1]]?.[subArr[2]] ??
                    item[subArr[0]]?.[subArr[1]];

                return (
                    subArrString &&
                    subArrString.toString().toLowerCase().includes(filterText.toLowerCase())
                );
            }
            // Else
            return item[el] && item[el].toString().toLowerCase().includes(filterText.toLowerCase());
        });
        return !!searchResult.includes(true);
    });

    useEffect(() => {
        if (filterText === '') {
            setShowSearchClear(false);
        }
    }, [filterText]);

    // End Filter Text

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
                setShowSearchClear(false);
            }
        };

        return (
            <Row style={{ display: 'contents' }}>
                <Col lg={3} className="p-0 mb-4">
                    <FilterComponent
                        onFilter={(e) => {
                            setShowSearchClear(true);
                            setFilterText(e.target.value);
                        }}
                        onClear={handleClear}
                        filterText={filterText}
                        showSearchClear={showSearchClear}
                    />
                </Col>
                <Col className="mb-4 text-end downloadCSV">
                    <Export onExport={() => downloadCSV(dataList)} />
                </Col>
            </Row>
        );
    }, [filterText, downloadCSV]);

    function ExpandableExpander(props) {
        return expandedComponent(props);
    }

    const customStyles = {
        borderLeft: '1px solid #ddd',
        headCells: {
            style: {
                paddingLeft: cellPadding || '5px',
                paddingRight: cellPadding || '5px',
                borderRight: `${borderWidth || '1px'} solid #ddd`,
            },
        },
        cells: {
            style: {
                paddingLeft: cellPadding || '5px',
                paddingRight: cellPadding || '5px',
                borderRight: `${borderWidth || '1px'} solid #ddd`,
            },
        },
    };

    return (
        <DataTable
            columns={columns}
            data={!isEmpty(filteredItems) ? filteredItems : dataList}
            defaultSortFieldID={1}
            noDataComponent=""
            pagination={pagination !== false}
            selectableRowsComponent={BootyCheckbox}
            paginationComponentOptions={paginationComponentOptions}
            paginationPerPage={10}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            progressPending={pending}
            progressComponent={<Loader />}
            responsive
            highlightOnHover
            expandableRows={!!expandableRows}
            expandableRowsHideExpander={!expandableRows}
            expandableRowsComponent={expandableRows && ExpandableExpander}
            expandOnRowClicked={!!expandableRows}
            expandableRowExpanded={() => !!expandableRows}
            onRowExpandToggled={() => !!expandableRows}
            conditionalRowStyles={conditionalRowStyles && conditionalRowStyles}
            fixedHeader
            fixedHeaderScrollHeight="450px"
            customStyles={customStyles}
        />
    );
}

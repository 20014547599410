/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Expired Medicine
 * Description: Expired Medicine Form: Create and Update
 * Date: 26/04/2022
 */

import { isArray } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import UrlService from '../../../services/UrlService';
import { useLowStock } from './ContextAPI';
import PartnerDataTable from './PartnerDataTable';

export default function FormInfo({ editData = null }) {
    const { setShow, setPartnerInfo } = useLowStock();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const empInfo = auth.employee_info;
    const layer = empInfo.layer_id;

    // Employee Info
    const partnerName = layer == 1 ? 'All' : empInfo?.partner_name;
    const partnerId = layer == 1 ? -1 : empInfo?.partner_id;

    // Local State
    const [partnerData, setPartnerData] = useState();
    const [partnerList, setPartnerList] = useState();

    const {
        // register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const { partner, getPartner } = CommonApiService();

    const dataList = useCallback(() => {
        getPartner(); // Partner
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner]);

    useEffect(() => {
        if (partnerData?.length > 0) {
            setPartnerList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...partnerData,
            ]);
        }
    }, [partnerData]);

    const d = new Date();
    d.setMonth(d.getMonth() - 34);
    const pDate = d;

    // Default Table Load Data
    useEffect(async () => {
        const data = {
            start_date: pDate.toLocaleDateString('sv-SE'),
            end_date: new Date().toLocaleDateString('sv-SE'),
            partner_ids: [partnerId],
        };
        try {
            const response = await axiosPrivate.post(
                UrlService.getAllNearlyExpiredMedicineUrl(layer),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setPartnerInfo(response.data.drug_list);

            if (response?.data?.status === 'error') {
                // ToastifyService.dbErrorNotify(response?.data?.msg);
                // const message = Object.keys(response.data.msg);
                // message.forEach((key) => {
                //     response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                // });
                // ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShow(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    }, []);

    const formSubmitHandler = async (data) => {
        const formData = {
            start_date: pDate.toLocaleDateString('sv-SE'),
            end_date: new Date().toLocaleDateString('sv-SE'),
            partner_ids: isArray(data?.partner_id)
                ? data?.partner_id.map((el) => el.value * 1)
                : data?.partner_id?.value
                ? [data.partner_id.value * 1]
                : [],
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.getAllNearlyExpiredMedicineUrl(layer),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setPartnerInfo(response.data.drug_list);
            if (response?.data?.status === 'error') {
                // ToastifyService.dbErrorNotify(response?.data?.msg);
                // const message = Object.keys(response.data.msg);
                // message.forEach((key) => {
                //     response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                // });
                // ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShow(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Card className="mt-3">
            <Card.Header className="cardHeader" style={{ background: '#ff8100' }}>
                <h3 className="text-white">Partner Warehouse: Items with low stock</h3>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Row>
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Partner Agency</Form.Label>
                                <Controller
                                    name="partner_id"
                                    id="partner_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            className="unhcrSelect"
                                            classNamePrefix={errors?.partner_id && 'invalidInput'}
                                            options={partnerList}
                                            isDisabled={auth?.employee_info?.partner_id}
                                            isMulti
                                            defaultValue={{ label: partnerName, value: partnerId }}
                                        />
                                    )}
                                    // rules={{ required: 'Please select your partner.' }}
                                />
                                {errors?.partner_id && (
                                    <span className="invalid">{errors?.partner_id?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mt-3 pt-1">
                            <Button
                                // className="btnSuccess"
                                onClick={handleSubmit(formSubmitHandler)}
                                type="button"
                                variant="warning"
                                // disabled={isSubmitted}
                            >
                                View Report
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <div className="dataTableHeaderNone">
                    <PartnerDataTable />
                </div>
            </Card.Body>
        </Card>
    );
}

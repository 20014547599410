/*
 * Author M. Atoar Rahman
 * Title: Common Service
 * Description: Fatch All Data: Generic, Suppliers, Manufacturers, Warehouse
 * Date: 15/03/2022
 */

import { useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import UrlService from './UrlService';

export default function CommonApiService() {
    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // Generic: Fatch All Data
    const [generic, setGeneric] = useState();
    const getGeneric = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllDrugGenericUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setGeneric(response?.data?.generic_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Drug Generic Data Not Found!');
            }
        }
    };
    // Suppliers: Fatch All Data
    const [suppliers, setSuppliers] = useState();
    const getSuppliers = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllSupplierUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setSuppliers(response?.data?.supplier_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Suppliers Data Not Found!');
            }
        }
    };
    // Manufacturers: Fatch All Data
    const [manufacturers, setManufacturers] = useState();
    const getManufacturers = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllManufacturerUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setManufacturers(response?.data?.manufacturer_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Manufacturer Data Not Found!');
            }
        }
    };

    // Therapeutic Action Class: Fatch All Data
    const [therapeutics, setTherapeutics] = useState();
    const getTherapeutics = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllTherapeuticUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setTherapeutics(response?.data?.therapeutic_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Manufacturer Data Not Found!');
            }
        }
    };

    // Center Warehouse: Fatch All Data
    const [centerWarehouse, setCenterWarehouse] = useState();
    const getCenterWarehouse = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllWarehouseInfoUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setCenterWarehouse(response?.data?.warehouse_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Center Warehouse Data Not Found!');
            }
        }
    };

    // Partner: Fatch All Data
    const [partner, setPartner] = useState();
    const getPartner = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllPartnerUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setPartner(response?.data?.partner_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Partner Data Not Found!');
            }
        }
    };

    // Facility: Fatch All Data
    const [facility, setFacility] = useState();
    const getFacility = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllFacilityUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setFacility(response?.data?.facility_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Facility Data Not Found!');
            }
        }
    };

    // Facility: Facility By Partner Id
    const [facilityByPartner, setFacilityByPartner] = useState();
    const getFacilityByPartner = async (id) => {
        try {
            const response = await axiosPrivate.get(UrlService.getFacilityByPartnerIdUrl(id), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setFacilityByPartner(response?.data?.facility_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Facility Data Not Found!');
            }
        }
    };

    // Dispensary: Fatch All Data
    const [dispensary, setDispensary] = useState();
    const getDispensary = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllDispensaryUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setDispensary(response?.data?.dispensary_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Dispensary Data Not Found!');
            }
        }
    };

    // Dispensary: Dispensary By Facility Id
    const [dispensaryByFacility, setDispensaryByFacility] = useState();
    const getDispensaryByFacility = async (id) => {
        try {
            const response = await axiosPrivate.get(UrlService.getDispensaryByFacilityIdUrl(id), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setDispensaryByFacility(response?.data?.dispensary_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Dispensary Data Not Found!');
            }
        }
    };

    // Drug Category: Fatch All Data
    const [drugCategory, setDrugCategory] = useState();
    const getDrugCategory = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllDrugCategoryUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setDrugCategory(response?.data?.drug_category_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Drug Category Data Not Found!');
            }
        }
    };

    // Drug Info: Fatch All Data from view table
    const [drugInfo, setDrugInfo] = useState();
    const getDrugInfo = async (catid) => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllDrugInfoUrl(catid), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setDrugInfo(response?.data?.drug_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Drug Category Data Not Found!');
            }
        }
    };

    const [poDrugInfo, setPoDrugInfo] = useState();
    const getPoDrugInfo = async (po_no) => {
        try {
            const response = await axiosPrivate.post(
                UrlService.getPoReportsByPoIdUrl(),
                JSON.stringify({ po_no }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setPoDrugInfo(response?.data?.request_data.purchased_items);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Drug Category Data Not Found!');
            }
        }
    };

    const [agencies, setAgencies] = useState();
    const getAgencies = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllOuterAgencyUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setAgencies(response?.data?.agency_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Manufacturer Data Not Found!');
            }
        }
    };

    const [purchaseOrder, setPurchaseOrder] = useState();
    const getPurchaseOrder = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllPurchaseOrderUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setPurchaseOrder(response?.data?.request_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Manufacturer Data Not Found!');
            }
        }
    };

    const [centerStockRecordMdicineList, setCenterStockRecordMdicineList] = useState();
    const getCenterStockRecordMdicineList = async (warehouse_id = 0) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.centerStockRecordMdicineList(warehouse_id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setCenterStockRecordMdicineList(response?.data?.drug_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Manufacturer Data Not Found!');
            }
        }
    };

    const [partnerStockRecordMdicineList, setPartnerStockRecordMdicineList] = useState();
    const getPartnerStockRecordMdicineList = async (partner_id = 0) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.partnerStockRecordMdicineList(partner_id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setPartnerStockRecordMdicineList(response?.data?.drug_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Manufacturer Data Not Found!');
            }
        }
    };

    const [facilityStockRecordMdicineList, setFacilityStockRecordMdicineList] = useState();
    const getFacilityStockRecordMdicineList = async (facility_id = 0) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.facilityStockRecordMdicineList(facility_id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setFacilityStockRecordMdicineList(response?.data?.drug_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Manufacturer Data Not Found!');
            }
        }
    };

    return {
        generic,
        getGeneric,
        suppliers,
        getSuppliers,
        manufacturers,
        getManufacturers,
        therapeutics,
        getTherapeutics,
        centerWarehouse,
        getCenterWarehouse,
        partner,
        getPartner,
        facility,
        getFacility,
        facilityByPartner,
        getFacilityByPartner,
        dispensary,
        getDispensary,
        dispensaryByFacility,
        getDispensaryByFacility,
        drugCategory,
        getDrugCategory,
        drugInfo,
        getDrugInfo,
        poDrugInfo,
        getPoDrugInfo,
        agencies,
        getAgencies,
        purchaseOrder,
        getPurchaseOrder,
        centerStockRecordMdicineList,
        getCenterStockRecordMdicineList,
        partnerStockRecordMdicineList,
        getPartnerStockRecordMdicineList,
        facilityStockRecordMdicineList,
        getFacilityStockRecordMdicineList,
    };
}

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Item Dispatch DataTable
 * Description: Dispensary Item Dispatch DataTable
 * Date: 26/04/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { useItemDispatch } from './ContextAPI';

export default function DataTable() {
    const { dispatchs, getAllData, setShowModal, setRowData } = useItemDispatch();
    const axiosPrivate = useAxiosPrivate();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const viewHander = async (row) => {
        try {
            const response = await axiosPrivate.get(UrlService.getDispatchItemDetailsUrl(row.id), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const row_info = {
                dispensary_name: row.dispensary_name,
                dispatch_date: row.dispatch_date,
                patient_id_no: row.patient_id_no,
                serial_no: row.serial_no,
                dispatch_item_info: response?.data?.dispatch_item_info,
            };

            setRowData(row_info);
            setShowModal(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const columns = [
        {
            name: 'Partner',
            selector: (row) => row.partner_name,
            sortable: true,
        },
        {
            name: 'Facility',
            selector: (row) => row.facility_name,
            sortable: true,
        },
        {
            name: 'Department',
            selector: (row) => row.dispensary_name,
            sortable: true,
        },
        {
            name: 'Patient ID No.',
            selector: (row) => row.patient_id_no,
            sortable: true,
        },
        {
            name: 'Patient SL.No.',
            selector: (row) => row.serial_no,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => row.dispatch_date,
            format: (row) => moment(row.dispatch_date).format('DD MMM YYYY'),
            sortable: true,
        },
        // {
        //     name: 'Status',
        //     selector: (row) => row.status,
        //     cell: (row) =>
        //         row.status === '1' ? (
        //             <Badge className="badgeActive" bg="success">
        //                 Active
        //             </Badge>
        //         ) : (
        //             <Badge className="badgeInactive" bg="success">
        //                 Inactive
        //             </Badge>
        //         ),
        // },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    className="btn btn-light btnSuccess"
                    variant="primary"
                    onClick={() => viewHander(row)}
                >
                    View
                </Button>
            ),
        },
    ];

    const search_column = {
        partner: 'Partner',
        facility: 'Facility',
        dispensary: 'dispensary',
        qty: 'Quantity',
        date: 'Date',
    };
    return useDataTable({ columns, data: dispatchs, search_column });
}

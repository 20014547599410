/*
 * Author M. Atoar Rahman
 * Title: AppRoute
 * Description: AppRoute
 * Date: 15/03/2022
 */

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MenuIndex from '../components/menuManagement/menu/Index';
import CenterBinCard from '../components/pages/CenterBinCard';
import CenterCurrentStock from '../components/pages/CenterCurrentStock';
import CenterStockIn from '../components/pages/CenterStockIn';
import CenterStockLoss from '../components/pages/CenterStockLoss';
import CenterStockRegister from '../components/pages/CenterStockRegister';
import ChangePassword from '../components/pages/ChangePassword';
import CodeSendToEmail from '../components/pages/CodeSendToEmail';
import ConsumptionReports from '../components/pages/ConsumptionReports';
import ConsumptionTally from '../components/pages/ConsumptionTally';
import Country from '../components/pages/Country';
import CurrentStock from '../components/pages/CurrentStock';
import CurrentStockEntry from '../components/pages/CurrentStockEntry';
import D1Approval from '../components/pages/D1Approval';
import D1Form from '../components/pages/D1Form';
import D2Approval from '../components/pages/D2Approval';
import D2Distribution from '../components/pages/D2Distribution';
import D2DistributionApproval from '../components/pages/D2DistributionApproval';
import D2FinalApproval from '../components/pages/D2FinalApproval';
import D2Form from '../components/pages/D2Form';
import D2PartnerApproval from '../components/pages/D2PartnerApproval';
import D3Reports from '../components/pages/D3Reports';
import D4Report from '../components/pages/D4Reports';
import Dashboard from '../components/pages/Dashboard';
import DispensaryCurrentStock from '../components/pages/DispensaryCurrentStock';
import DispensaryInfo from '../components/pages/DispensaryInfo';
import DispensaryInternalRequest from '../components/pages/DispensaryInternalRequest';
import DispensaryInternalRequestApprove from '../components/pages/DispensaryInternalRequestApprove';
import DispensaryItemDispatch from '../components/pages/DispensaryItemDispatch';
import DispensaryStockLoss from '../components/pages/DispensaryStockLoss';
import DispensaryStockReceive from '../components/pages/DispensaryStockReceive';
import DispensaryStockReturn from '../components/pages/DispensaryStockReturn';
import DispensaryStockReturnReceive from '../components/pages/DispensaryStockReturnReceive';
import DispensaryStockReturnReceiveApproval from '../components/pages/DispensaryStockReturnReceiveApproval';
import Donation from '../components/pages/Donation';
import Drug from '../components/pages/Drug';
import DrugCategory from '../components/pages/DrugCategory';
import DrugGeneric from '../components/pages/DrugGeneric';
import DrugGroup from '../components/pages/DrugGroup';
import DrugManufacturer from '../components/pages/DrugManufacturer';
import DrugPresentationStrength from '../components/pages/DrugPresentationStrength';
import DrugPresentationType from '../components/pages/DrugPresentationType';
import DrugSupplier from '../components/pages/DrugSupplier';
import EmployeeInformation from '../components/pages/EmployeeInformation';
import ExpiredMedicine from '../components/pages/ExpiredMedicine';
import FacilityBinCard from '../components/pages/FacilityBinCard';
import FacilityCurrentStock from '../components/pages/FacilityCurrentStock';
import FacilityInfomation from '../components/pages/FacilityInfomation';
import FacilityInternalRequest from '../components/pages/FacilityInternalRequest';
import FacilityInternalRequestApprove from '../components/pages/FacilityInternalRequestApprove';
import FacilityInternalRequestCentral from '../components/pages/FacilityInternalRequestCentral';
import FacilityInternalRequestCentralApproval from '../components/pages/FacilityInternalRequestCentralApproval';
import FacilityInternalRequestPartner from '../components/pages/FacilityInternalRequestPartner';
import FacilityInternalRequestPartnerApproval from '../components/pages/FacilityInternalRequestPartnerApproval';
import FacilityInternalRequestPartnerReceive from '../components/pages/FacilityInternalRequestPartnerReceive';
import FacilityInternalRequestPartnerReceiveApproval from '../components/pages/FacilityInternalRequestPartnerReceiveApproval';
import FacilityInternalRequestUnhcr from '../components/pages/FacilityInternalRequestUnhcr';
import FacilityInternalRequestUnhcrApproval from '../components/pages/FacilityInternalRequestUnhcrApproval';
import FacilityStockDistribution from '../components/pages/FacilityStockDistribution';
import FacilityStockDistributionApproval from '../components/pages/FacilityStockDistributionApproval';
import FacilityStockLoss from '../components/pages/FacilityStockLoss';
import FacilityStockReceive from '../components/pages/FacilityStockReceive';
import FacilityStockReceiveApproval from '../components/pages/FacilityStockReceiveApproval';
import FacilityStockRegister from '../components/pages/FacilityStockRegister';
import FacilityStockReturn from '../components/pages/FacilityStockReturn';
import FacilityStockReturnApproval from '../components/pages/FacilityStockReturnApproval';
import FacilityStockReturnReceive from '../components/pages/FacilityStockReturnReceive';
import FacilityStockReturnReceiveApproval from '../components/pages/FacilityStockReturnReceiveApproval';
import ForgetPassword from '../components/pages/ForgetPassword';
import InternalRequestFacilityReceive from '../components/pages/InternalRequestFacilityReceive';
import InternalRequestFacilityReceiveApproval from '../components/pages/InternalRequestFacilityReceiveApproval';
import LayerChaining from '../components/pages/LayerChaining';
import Login from '../components/pages/LoginForm';
import NearlyExpiredMedicine from '../components/pages/NearlyExpiredMedicine';
import NotFound from '../components/pages/NotFound';
import OuterAgency from '../components/pages/OuterAgency';
import PartnerBinCard from '../components/pages/PartnerBinCard';
import PartnerCurrentStock from '../components/pages/PartnerCurrentStock';
import PartnerInformation from '../components/pages/PartnerInformation';
import PartnerInternalRequest from '../components/pages/PartnerInternalRequest';
import PartnerInternalRequestApprove from '../components/pages/PartnerInternalRequestApprove';
import PartnerInternalRequestCentral from '../components/pages/PartnerInternalRequestCentral';
import PartnerInternalRequestCentralApproval from '../components/pages/PartnerInternalRequestCentralApproval';
import PartnerInternalRequestReceive from '../components/pages/PartnerInternalRequestReceive';
import PartnerInternalRequestReceiveApproval from '../components/pages/PartnerInternalRequestReceiveApproval';
import PartnerInternalRequestUnhcr from '../components/pages/PartnerInternalRequestUnhcr';
import PartnerInternalRequestUnhcrApproval from '../components/pages/PartnerInternalRequestUnhcrApproval';
import PartnerStockDistribution from '../components/pages/PartnerStockDistribution';
import PartnerStockDistributionApproval from '../components/pages/PartnerStockDistributionApproval';
import PartnerStockLoss from '../components/pages/PartnerStockLoss';
import PartnerStockReceive from '../components/pages/PartnerStockReceive';
import PartnerStockReceiveApproval from '../components/pages/PartnerStockReceiveApproval';
import PartnerStockRegister from '../components/pages/PartnerStockRegister';
import PartnerStockReturn from '../components/pages/PartnerStockReturn';
import PartnerStockReturnApproval from '../components/pages/PartnerStockReturnApproval';
import PartnerStockReturnReceive from '../components/pages/PartnerStockReturnReceive';
import PartnerStockReturnReceiveApproval from '../components/pages/PartnerStockReturnReceiveApproval';
import PermissionDenied from '../components/pages/PermissionDenied';
import PoReports from '../components/pages/PoReports';
import PoWiseStockReceive from '../components/pages/PoWiseStockReceive';
import ProductList from '../components/pages/ProductList';
import PurchaseOrder from '../components/pages/PurchaseOrder';
import SleepingStock from '../components/pages/SleepingStock';
import StockReturnRequest from '../components/pages/StockReturnRequest';
import StockReturnRequestApproval from '../components/pages/StockReturnRequestApproval';
import StockReturnRequestCentralReceive from '../components/pages/StockReturnRequestCentralReceive';
import StockReturnRequestCentralReceiveApproval from '../components/pages/StockReturnRequestCentralReceiveApproval';
import StockReturnRequestFacility from '../components/pages/StockReturnRequestFacility';
import StockReturnRequestFacilityApproval from '../components/pages/StockReturnRequestFacilityApproval';
import StockReturnRequestPartner from '../components/pages/StockReturnRequestPartner';
import StockReturnRequestPartnerApproval from '../components/pages/StockReturnRequestPartnerApproval';
import TherapeuticAction from '../components/pages/TherapeuticAction';
import Unauthorized from '../components/pages/Unauthorized';
import UserInformation from '../components/pages/UserInformation';
import WarehouseInfo from '../components/pages/WarehouseInfo';
import RoleIndex from '../components/permissionManagement/role/Index';
import RolePermissionIndex from '../components/permissionManagement/rolePermission/Index';
import { AuthProvider } from '../contexts/AuthContext';
import PersistLogin from './PersistLogin';
import PublicRoute from './PublicRoute';
import RequireAuth from './RequireAuth';

const ROLES = {
    Admin: 1,
    User: 2,
    Editor: 3,
};

function AppRoute() {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    {/* public routes */}
                    <Route element={<PublicRoute />}>
                        <Route path="/login" element={<Login />} />
                        <Route path="/forget-password" element={<ForgetPassword />} />
                        <Route path="/code-send-to-email" element={<CodeSendToEmail />} />
                        <Route path="/unauthorized" element={<Unauthorized />} />
                    </Route>

                    {/* we want to protect these routes */}
                    <Route element={<PersistLogin />}>
                        <Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}>
                            <Route path="/" element={<Dashboard />} />
                            <Route
                                path="/permission-management/role-info"
                                element={<RoleIndex />}
                            />
                            <Route
                                path="/permission-management/role-permission"
                                element={<RolePermissionIndex />}
                            />
                            <Route path="/menu-management/menu-info" element={<MenuIndex />} />
                            <Route path="productlist" element={<ProductList />} />
                            <Route path="dispensary" element={<DispensaryInfo />} />
                            <Route path="user-info" element={<UserInformation />} />
                            <Route path="employee-info" element={<EmployeeInformation />} />
                            <Route path="partner-info" element={<PartnerInformation />} />
                            <Route path="facility-info" element={<FacilityInfomation />} />
                            <Route path="drug-generic" element={<DrugGeneric />} />
                            <Route path="drug-group" element={<DrugGroup />} />
                            <Route path="drug-category" element={<DrugCategory />} />
                            <Route path="drug-manufacturer" element={<DrugManufacturer />} />
                            <Route path="drug-supplier" element={<DrugSupplier />} />
                            <Route path="layer-chaining" element={<LayerChaining />} />
                            <Route
                                path="drug-presentation-type"
                                element={<DrugPresentationType />}
                            />
                            <Route
                                path="drug-presentation-strength"
                                element={<DrugPresentationStrength />}
                            />
                            <Route path="therapeutic-action" element={<TherapeuticAction />} />
                            {/* <Route path="drug-info" element={<DrugInformation />} /> */}
                            <Route path="drug-info" element={<Drug />} />
                            <Route path="country" element={<Country />} />
                            <Route path="warehouse-info" element={<WarehouseInfo />} />
                            <Route path="center-stock-in" element={<CenterStockIn />} />
                            <Route path="d1-preparation" element={<D1Form />} />
                            <Route path="d1-approval" element={<D1Approval />} />
                            <Route path="d2-partner-approval" element={<D2PartnerApproval />} />
                            <Route path="d2-preparation" element={<D2Form />} />
                            <Route path="consumption-tally" element={<ConsumptionTally />} />
                            <Route path="consumption-reports" element={<ConsumptionReports />} />
                            <Route path="expired-medicine" element={<ExpiredMedicine />} />
                            <Route
                                path="nearly-expired-medicine"
                                element={<NearlyExpiredMedicine />}
                            />
                            <Route path="sleeping-stock" element={<SleepingStock />} />
                            <Route path="po-wise-stock-receive" element={<PoWiseStockReceive />} />
                            <Route path="item-dispatch" element={<DispensaryItemDispatch />} />
                            <Route
                                path="dispensary-internal-request"
                                element={<DispensaryInternalRequest />}
                            />
                            <Route path="dispensary-stock-loss" element={<DispensaryStockLoss />} />
                            <Route
                                path="dispensary-stock-return"
                                element={<DispensaryStockReturn />}
                            />
                            <Route
                                path="dispensary-stock-return-receive"
                                element={<DispensaryStockReturnReceive />}
                            />
                            <Route
                                path="dispensary-stock-return-receive-approval"
                                element={<DispensaryStockReturnReceiveApproval />}
                            />
                            <Route
                                path="dispensary-internal-request-approval"
                                element={<DispensaryInternalRequestApprove />}
                            />
                            <Route
                                path="facility-internal-request-approval"
                                element={<FacilityInternalRequestApprove />}
                            />
                            <Route
                                path="facility-internal-request"
                                element={<FacilityInternalRequest />}
                            />
                            <Route path="partner-stock-receive" element={<PartnerStockReceive />} />
                            <Route
                                path="partner-stock-receive-approval"
                                element={<PartnerStockReceiveApproval />}
                            />
                            <Route
                                path="partner-stock-distribution"
                                element={<PartnerStockDistribution />}
                            />
                            <Route
                                path="partner-stock-distribution-approval"
                                element={<PartnerStockDistributionApproval />}
                            />
                            <Route path="partner-stock-return" element={<PartnerStockReturn />} />
                            <Route
                                path="partner-stock-return-approval"
                                element={<PartnerStockReturnApproval />}
                            />
                            <Route
                                path="partner-stock-return-receive"
                                element={<PartnerStockReturnReceive />}
                            />
                            <Route
                                path="partner-stock-return-receive-approval"
                                element={<PartnerStockReturnReceiveApproval />}
                            />
                            <Route path="facility-stock-loss" element={<FacilityStockLoss />} />
                            <Route
                                path="facility-stock-receive"
                                element={<FacilityStockReceive />}
                            />
                            <Route
                                path="facility-stock-receive-approval"
                                element={<FacilityStockReceiveApproval />}
                            />
                            <Route
                                path="facility-stock-distribution"
                                element={<FacilityStockDistribution />}
                            />
                            <Route
                                path="facility-stock-distribution-approval"
                                element={<FacilityStockDistributionApproval />}
                            />
                            <Route path="facility-stock-return" element={<FacilityStockReturn />} />
                            <Route
                                path="facility-stock-return-approval"
                                element={<FacilityStockReturnApproval />}
                            />
                            <Route
                                path="facility-stock-return-receive"
                                element={<FacilityStockReturnReceive />}
                            />
                            <Route
                                path="facility-stock-return-receive-approval"
                                element={<FacilityStockReturnReceiveApproval />}
                            />
                            <Route path="partner-stock-loss" element={<PartnerStockLoss />} />
                            <Route path="center-stock-loss" element={<CenterStockLoss />} />

                            <Route
                                path="dispensary-stock-receive"
                                element={<DispensaryStockReceive />}
                            />
                            <Route path="d2-approval" element={<D2Approval />} />
                            <Route path="d2-final-approval" element={<D2FinalApproval />} />
                            <Route path="d2-distribution" element={<D2Distribution />} />
                            <Route path="current-stock" element={<CurrentStock />} />
                            <Route path="center-current-stock" element={<CenterCurrentStock />} />
                            <Route path="partner-current-stock" element={<PartnerCurrentStock />} />
                            <Route
                                path="facility-current-stock"
                                element={<FacilityCurrentStock />}
                            />
                            <Route
                                path="dispensary-current-stock"
                                element={<DispensaryCurrentStock />}
                            />
                            <Route
                                path="d2-distribution-approval"
                                element={<D2DistributionApproval />}
                            />
                            <Route path="center-bin-card" element={<CenterBinCard />} />
                            <Route path="partner-bin-card" element={<PartnerBinCard />} />
                            <Route path="facility-bin-card" element={<FacilityBinCard />} />
                            <Route path="center-stock-register" element={<CenterStockRegister />} />
                            <Route
                                path="partner-stock-register"
                                element={<PartnerStockRegister />}
                            />
                            <Route
                                path="facility-stock-register"
                                element={<FacilityStockRegister />}
                            />
                            <Route path="stock-return-request" element={<StockReturnRequest />} />
                            <Route
                                path="stock-return-request-approval"
                                element={<StockReturnRequestApproval />}
                            />
                            <Route
                                path="stock-return-request-central-receive"
                                element={<StockReturnRequestCentralReceive />}
                            />
                            <Route
                                path="stock-return-request-central-receive-approval"
                                element={<StockReturnRequestCentralReceiveApproval />}
                            />
                            <Route
                                path="stock-return-request-partner"
                                element={<StockReturnRequestPartner />}
                            />
                            <Route
                                path="stock-return-request-partner-approval"
                                element={<StockReturnRequestPartnerApproval />}
                            />
                            <Route
                                path="stock-return-request-facility"
                                element={<StockReturnRequestFacility />}
                            />
                            <Route
                                path="stock-return-request-facility-approval"
                                element={<StockReturnRequestFacilityApproval />}
                            />
                            <Route path="donation" element={<Donation />} />
                            <Route path="d3-reports" element={<D3Reports />} />
                            <Route path="d4-reports" element={<D4Report />} />
                            <Route path="outer-agencies" element={<OuterAgency />} />
                            <Route path="purchase-order" element={<PurchaseOrder />} />
                            <Route path="po-reports" element={<PoReports />} />
                            <Route path="*" element={<NotFound />} />
                            <Route path="*" element={<PermissionDenied />} />
                            <Route path="change-password" element={<ChangePassword />} />
                            <Route path="current-stock-entry" element={<CurrentStockEntry />} />
                            <Route
                                path="facility-internal-request-receive"
                                element={<InternalRequestFacilityReceive />}
                            />
                            <Route
                                path="facility-internal-request-receive-approval"
                                element={<InternalRequestFacilityReceiveApproval />}
                            />
                            <Route
                                path="facility-internal-request-partner-approval"
                                element={<FacilityInternalRequestPartnerApproval />}
                            />
                            <Route
                                path="facility-internal-request-partner-init"
                                element={<FacilityInternalRequestPartner />}
                            />
                            <Route
                                path="facility-internal-request-partner-receive"
                                element={<FacilityInternalRequestPartnerReceive />}
                            />
                            <Route
                                path="facility-internal-request-partner-receive-approval"
                                element={<FacilityInternalRequestPartnerReceiveApproval />}
                            />
                            <Route
                                path="facility-internal-request-unhcr-init"
                                element={<FacilityInternalRequestUnhcr />}
                            />
                            <Route
                                path="facility-internal-request-unhcr-approval"
                                element={<FacilityInternalRequestUnhcrApproval />}
                            />
                            <Route
                                path="facility-internal-request-central-init"
                                element={<FacilityInternalRequestCentral />}
                            />
                            <Route
                                path="facility-internal-request-central-approval"
                                element={<FacilityInternalRequestCentralApproval />}
                            />
                            <Route
                                path="partner-internal-request"
                                element={<PartnerInternalRequest />}
                            />
                            <Route
                                path="partner-internal-request-approval"
                                element={<PartnerInternalRequestApprove />}
                            />
                            <Route
                                path="partner-internal-request-unhcr-init"
                                element={<PartnerInternalRequestUnhcr />}
                            />
                            <Route
                                path="partner-internal-request-unhcr-approval"
                                element={<PartnerInternalRequestUnhcrApproval />}
                            />
                            <Route
                                path="partner-internal-request-central-init"
                                element={<PartnerInternalRequestCentral />}
                            />
                            <Route
                                path="partner-internal-request-central-approval"
                                element={<PartnerInternalRequestCentralApproval />}
                            />
                            <Route
                                path="partner-internal-request-receive"
                                element={<PartnerInternalRequestReceive />}
                            />
                            <Route
                                path="partner-internal-request-receive-approval"
                                element={<PartnerInternalRequestReceiveApproval />}
                            />
                        </Route>
                    </Route>
                    {/* Not Found */}
                    {/* <Route path="*" element={<NotFound />} /> */}
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default AppRoute;

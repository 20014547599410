import moment from 'moment';
import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import logo from '../../../../assets/unhcr-logo-blue.png';
import { usePoStock } from '../ContextAPI';

function ComponentToPrint(props, ref) {
    const { rowData } = usePoStock();

    return (
        <fieldset ref={ref} className="customFieldset donation p-5">
            <div className="text-center mb-4">
                <img width="200px" src={logo} alt="" />
                <h5 className="mt-3 mb-5 text-center">
                    <b>PO Wise Stock Receive Details Status</b>
                </h5>
            </div>
            {rowData.status === 'pending' ? (
                <>
                    <Row className="my-3">
                        <Col lg={3}>
                            <p className="mb-0">
                                <b>PO No.:</b> {rowData.po_no}
                            </p>
                        </Col>
                        <Col lg={3}>
                            <p className="mb-0">
                                <b>PO Date:</b> {moment(rowData.po_date).format('DD MMM YYYY')}
                            </p>
                        </Col>
                        <Col lg={3}>
                            <p className="mb-0">
                                <b>Supplied Qty:</b> {rowData.total_supply_qty}
                            </p>
                        </Col>
                        <Col lg={3}>
                            <p className="mb-0">
                                <b>Supplier Name:</b> {rowData.supplier_name}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>UID</th>
                                    <th>Item Name</th>
                                    <th>Presentation Types</th>
                                    <th>Presentation Strengths</th>
                                    <th>Purchase Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowData?.records?.map((el) => (
                                    <tr key={Math.random()}>
                                        <td>{el.code_no}</td>
                                        <td>{el.drug_name}</td>
                                        <td>{el.presentation_types}</td>
                                        <td>{el.presentation_strengths}</td>
                                        <td>{el.purchase_qty}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                </>
            ) : (
                <>
                    <Row className="my-3">
                        <Col lg={4}>
                            <p className="mb-0">
                                <b>PO No.:</b> {rowData.po_no}
                            </p>
                            <p className="mb-0">
                                <b>PO Date:</b> {moment(rowData.po_date).format('DD MMM YYYY')}
                            </p>
                        </Col>
                        <Col lg={4}>
                            <p className="mb-0">
                                <b>Supplied Qty:</b> {rowData.total_supply_qty}
                            </p>
                            <p className="mb-0">
                                <b>Received Qty:</b> {rowData.total_receive_qty}
                            </p>
                        </Col>
                        <Col lg={4}>
                            <p className="mb-0">
                                <b>Supplier Name:</b> {rowData.supplier_name}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>UID</th>
                                    <th>Item Name</th>
                                    <th>Presentation Types</th>
                                    <th>Presentation Strengths</th>
                                    <th>Supplied Qty</th>
                                    <th>Received Qty</th>
                                    <th>Rejected Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowData?.records?.map((el) => (
                                    <tr key={Math.random()}>
                                        <td>{el.code_no}</td>
                                        <td>{el.drug_name}</td>
                                        <td>{el.presentation_types}</td>
                                        <td>{el.presentation_strengths}</td>
                                        <td>{el.invoice_qty}</td>
                                        <td>{el.receive_qty}</td>
                                        <td>{el.rejected_qty}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                </>
            )}
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;

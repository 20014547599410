/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useStockReturnRequest } from './ContextAPI';
import ReturnTable from './ReturnTable';

export default function FormInfo() {
    const { setShow, rowData, returnList, firstSubmitInfo, setListShow } = useStockReturnRequest();
    const { drugCategory, getDrugCategory, centerWarehouse, getCenterWarehouse } =
        CommonApiService();

    // Local State
    const [warehouseData, setWarehouseData] = useState();
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const {
        formState: { errors },
        register,
        reset,
        handleSubmit,
        setValue,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        if (!isEmpty(returnList)) {
            setValue('itemDetails', returnList);
        }
    }, [returnList]);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
        getCenterWarehouse(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setWarehouseData(
            centerWarehouse?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [centerWarehouse]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <ReturnTable data={rowData?.filter((el) => el.category_id == item.id)} />
            </Tab>
        ));

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            central_warehouse_id: data?.central_warehouse_id?.value,
            partner_id: firstSubmitInfo?.partner_id,
            facility_id: firstSubmitInfo?.facility_id,
            dispensary_id: firstSubmitInfo?.dispensary_id,
        };

        try {
            await axiosPrivate.post(
                UrlService.unhcrReturnRequestSbumitUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.updateNotify('Submit Successfull');
            setListShow(true);
            reset();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setShow(false);
    };

    register('itemDetails', { required: 'Request quantity is required!' });

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Item Return Request</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to Form
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-4">
                            <Form.Label>
                                Warehouse <span className="invalid">*</span>
                            </Form.Label>

                            <Controller
                                name="central_warehouse_id"
                                id="central_warehouse_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={
                                            errors?.central_warehouse_id && 'invalidInput'
                                        }
                                        options={warehouseData}
                                    />
                                )}
                                rules={{ required: 'Please select your warehouse.' }}
                            />
                            {errors.central_warehouse_id && (
                                <span className="invalid">
                                    {errors.central_warehouse_id?.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Tabs
                        activeKey={page}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 ps-3"
                        // eslint-disable-next-line radix
                        onSelect={(k) => setPage(parseInt(k))}
                    >
                        {tableDataDisplay()}
                    </Tabs>
                </Row>
                <Row>
                    {errors?.itemDetails && (
                        <span className="invalid">{errors?.itemDetails?.message}</span>
                    )}
                </Row>

                <div className="formFooter">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="btnInfo me-2 ms-2"
                        variant="primary"
                        style={{ minWidth: '6rem' }}
                        disabled={page === 1}
                        onClick={() => {
                            setPage((currPage) => currPage - 1);
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        type="button"
                        className={`btnSuccess ${page === pageLength && 'd-none'}`}
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        onClick={() => setPage((currPage) => currPage + 1)}
                    >
                        Next
                    </Button>

                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className={`btnSuccess ${page !== pageLength && 'd-none'}`}
                        type="button"
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        disabled={isSubmitted}
                    >
                        Submit
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}

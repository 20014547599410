/*
 * Author M. Atoar Rahman
 * Title: PO Wise Stock Receive
 * Description: Page for PO Wise Stock Receive
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { PoStockProvider } from '../misReports/poWiseStockReceive/ContextAPI';
import Index from '../misReports/poWiseStockReceive/Index';
import PermissionDenied from './PermissionDenied';

export default function PoWiseStockReceive() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['po-wise-stock-receive']) return <PermissionDenied />;

    return (
        <PoStockProvider>
            <Index />
        </PoStockProvider>
    );
}

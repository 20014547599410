/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Item Dispatch
 * Description: Dispensary Item Dispatch Form: Create and Update
 * Date: 26/04/2022
 */

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useItemDispatch } from './ContextAPI';
// import CommonApiService from '../../../services/CommonApiService';
import { useAuth } from '../../../contexts/AuthContext';
import UrlService from '../../../services/UrlService';

export default function FormInfo({ editData = null }) {
    // Dependancy
    const { auth } = useAuth();
    // const { partner, getPartner, facility, getFacility, dispensary, getDispensary } =
    //     CommonApiService();
    const { addItemDispatch, editItemDispatch, setShow, setRowData } = useItemDispatch();
    const axiosPrivate = useAxiosPrivate();
    // Local State
    // const [partnerData, setPartnerData] = useState();
    // const [facilityData, setFacilityData] = useState();
    // const [dispensaryData, setDispensaryData] = useState();
    // const [dispensaryRowData, setDispensaryRowData] = useState();

    // Dispatch Details Info
    const [dispatchDetails, setDispatchDetails] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    // const [dispatchDetailShowModal, setDispatchDetailShowModal] = useState(false); // For Edit
    // const [dispatchDetailRowData, setDispatchDetailData] = useState({}); // For Edit

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                dispatch_date: editData ? new Date(editData.dispatch_date) : new Date(),
                // partner_id: editData && {
                //     label: editData?.partner?.name,
                //     value: editData?.partner_id,
                // },
                // facility_id: editData && {
                //     label: editData?.facility?.name,
                //     value: editData?.facility_id,
                // },
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            dispatch_date: editData ? new Date(editData.dispatch_date) : new Date(),
            // partner_id: editData && {
            //     label: editData?.partner?.name,
            //     value: editData?.partner_id,
            // },
            // facility_id: editData && {
            //     label: editData?.facility?.name,
            //     value: editData?.facility_id,
            // },
        });
    }, [editData]);

    // Dispatch Details
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        control: control2,
        reset: reset2,
        setValue: setValue2,
        watch,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        // defaultValues: useMemo(
        //     () => ({
        //         item:,
        //     }),
        //     [item]
        // ),
    });

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => console.log(value, name, type));
        return () => subscription.unsubscribe();
    }, [watch]);

    const dispensaryId = auth?.employee_info?.dispensary_id;

    // Set default values
    useEffect(() => {
        setValue('dispatchDetails', dispatchDetails);
    }, [dispatchDetails]);

    const [dispatchItem, setDispatchItem] = useState();

    const dispatchItemHandler = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getDispatchItemsByIdUrl(dispensaryId),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const itemInfo = response?.data?.dispatch_items.map((el) => ({
                value: el.drug_id,
                label: el.drug_name,
                available_stock: el.available_stock,
            }));
            setDispatchItem(itemInfo);

            console.log(response);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    useEffect(() => {
        dispatchItemHandler();
    }, []);

    const getRowData = (data) => {
        setValue2('available_stock', data?.available_stock);
    };

    const dispatchDetailAppendHandler = (data) => {
        const formDetailsData = {
            ...data,
            item_id: data.item.value,
            item_name: data.item.label,
        };

        setDispatchDetails([...dispatchDetails, formDetailsData]);
        reset2({
            item: '',
            available_stock: '',
            dispatch_qty: '',
        });
    };

    // console.log({ dispatchDetails });

    const dispatchDetailRemoveHandler = (id) => {
        const updatedDispatchDetail = dispatchDetails && dispatchDetails.filter((m) => m.id !== id);
        setDispatchDetails(updatedDispatchDetail);
    };

    // Access from context API
    // const dataList = useCallback(() => {
    //     getPartner(); // Partner
    //     getFacility(); // Facility
    //     getDispensary(); // Dispensary
    // }, []);

    // useEffect(() => {
    //     dataList();
    // }, [dataList]);

    // useEffect(() => {
    //     // Partner
    //     setPartnerData(
    //         partner?.map((el) => ({
    //             value: el.id,
    //             label: el.name,
    //         }))
    //     );
    //     // Facility
    //     setFacilityData(
    //         facility?.map((el) => ({
    //             value: el.id,
    //             label: el.name,
    //         }))
    //     );
    //     // Dispensary
    //     setDispensaryData(
    //         dispensary?.map((el) => ({
    //             value: el.id,
    //             label: el.name,
    //         }))
    //     );
    // }, [partner, facility, dispensary]);

    // Form Close
    const handleClose = () => {
        setShow(false);
        setRowData('');
    };

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            // partner_id: data.partner_id.value,
            // facility_id: data.facility_id.value,
            // dispensary_id: data.dispensary_id.value,
            dispatch_date: data.dispatch_date.toLocaleDateString('sv-SE'),
        };

        // console.log({ formData });

        if (editData) {
            editItemDispatch(formData, editData.id); // Update Data
        } else {
            addItemDispatch(formData); // Create Data
        }
        reset();
    };

    register('dispatchDetails', { required: 'Dispatch item is required!' });

    return (
        <Form>
            <fieldset style={{ background: '#f3f3f3' }} className="customFieldset p-3 pb-0 mb-3">
                <Row>
                    {/* <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Partner<span className="invalid">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        name="partner_id"
                                        id="partnerId"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.partner_id && 'invalidInput'
                                                }
                                                options={partnerData}
                                            />
                                        )}
                                        rules={{ required: 'Please select your partner.' }}
                                    />

                                    {errors.partner_id && (
                                        <span className="invalid">
                                            {errors.partner_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Dispensary<span className="invalid">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        name="dispensary_id"
                                        id="dispensary_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.dispensary_id && 'invalidInput'
                                                }
                                                options={dispensaryData}
                                                onBlur={() => setDispensaryRowData(field.value)}
                                            />
                                        )}
                                        rules={{ required: 'Please select your dispensary.' }}
                                    />
                                    {errors.dispensary_id && (
                                        <span className="invalid">
                                            {errors.dispensary_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group> */}
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Patient ID No.<span className="invalid">*</span>
                            </Form.Label>
                            <Form.Control
                                name="patient_id_no"
                                id="patient_id_no"
                                type="text"
                                placeholder="Enter ID No."
                                autoComplete="off"
                                {...register('patient_id_no', {
                                    required: 'Please enter your patient ID No.',
                                })}
                                className={errors?.patient_id_no && 'invalidInput'}
                            />
                            {errors.patient_id_no && (
                                <span className="invalid">{errors.patient_id_no?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    {editData && (
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Patient SL.No.<span className="invalid">*</span>
                                </Form.Label>
                                <Form.Control
                                    name="patient_sl_no"
                                    id="patient_sl_no"
                                    type="text"
                                    placeholder="Enter SL.No."
                                    autoComplete="off"
                                    {...register('patient_sl_no', {
                                        required: 'Please enter your patient SL.No.',
                                    })}
                                    className={errors?.patient_sl_no && 'invalidInput'}
                                />
                                {errors.patient_sl_no && (
                                    <span className="invalid">{errors.patient_sl_no?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                    )}
                    {/* </Col> */}
                    <Col lg={4}>
                        {/* <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Facility<span className="invalid">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        name="facility_id"
                                        id="facilityId"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.facility_id && 'invalidInput'
                                                }
                                                options={facilityData}
                                            />
                                        )}
                                        rules={{ required: 'Please select your facility.' }}
                                    />
                                    {errors.facility_id && (
                                        <span className="invalid">
                                            {errors.facility_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group> */}
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Date<span className="invalid">*</span>
                            </Form.Label>
                            <Controller
                                control={control}
                                name="dispatch_date"
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        closeOnScroll
                                        placeholderText="Select date"
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        className="form-control"
                                        popperPlacement="top-end"
                                        // minDate={new Date()}
                                        maxDate={new Date()}
                                    />
                                )}
                                rules={{
                                    valueAsDate: true,
                                }}
                            />
                            {errors.date && <span className="invalid">{errors.date?.message}</span>}
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>
            <Row>
                <Row>
                    <Col lg={7} className="pe-0">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Item<span className="invalid">*</span>
                            </Form.Label>
                            <Controller
                                name="item"
                                id="item"
                                control={control2}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors2?.item && 'invalidInput'}
                                        options={dispatchItem}
                                        onBlur={() => getRowData(field.value)}
                                    />
                                )}
                                rules={{
                                    required: 'Please select the item.',
                                }}
                            />
                            {errors2.item && (
                                <span className="invalid">{errors2.item?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="pe-0">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Available Stock<span className="invalid">*</span>
                            </Form.Label>
                            <Form.Control
                                name="available_stock"
                                id="available_stock"
                                type="text"
                                placeholder=""
                                autoComplete="off"
                                {...register2('available_stock', {
                                    required: 'Field is required!',
                                })}
                                className={errors2?.available_stock && 'invalidInput'}
                            />
                            {errors2.available_stock && (
                                <span className="invalid">{errors2.available_stock?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="pe-0">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Dispatch Qty<span className="invalid">*</span>
                            </Form.Label>
                            <Form.Control
                                name="dispatch_qty"
                                id="dispatch_qty"
                                type="text"
                                placeholder=""
                                autoComplete="off"
                                {...register2('dispatch_qty', {
                                    required: 'Field is required!',
                                })}
                                className={errors2?.dispatch_qty && 'invalidInput'}
                            />
                            {errors2.dispatch_qty && (
                                <span className="invalid">{errors2.dispatch_qty?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={1} className="mt-1 pe-0">
                        <Button
                            className="btnSteelBlue mt-3"
                            variant="primary"
                            onClick={handleSubmit2(dispatchDetailAppendHandler)}
                        >
                            Add
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        {errors.dispatchDetails && (
                            <span className="invalid">{errors.dispatchDetails?.message}</span>
                        )}
                        <Table className="borderDashed" responsive="sm">
                            <tbody>
                                {dispatchDetails.map((item) => (
                                    <tr key={Math.random()}>
                                        <td>{item.item_name}</td>
                                        <td>{item.available_stock}</td>
                                        <td>{item.dispatch_qty}</td>
                                        <td className="text-end">
                                            {/* <Button
                                                className="btn btn-light actionBtn"
                                                variant="primary"
                                                onClick={() => dispatchDetailModalHandler(item, i)}
                                            >
                                                <i className="fas fa-edit" />
                                            </Button> */}
                                            <Button
                                                className="btn btn-light actionBtn"
                                                variant="primary"
                                                onClick={() =>
                                                    dispatchDetailRemoveHandler(item.vehicle_no)
                                                }
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {errors.transports && (
                            <span className="invalid">{errors.transports?.message}</span>
                        )}
                    </Col>
                </Row>
            </Row>

            <div className="formFooter">
                <Button className="btnCancel me-2" variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    onClick={handleSubmit(formSubmitHandler)}
                    className="btnSuccess"
                    type="button"
                    variant="success"
                    disabled={isSubmitted}
                >
                    {editData ? 'Update' : 'Submit'}
                </Button>
            </div>
        </Form>
    );
}

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { ApprovalProvider } from '../unhcr/facilityInternalRequest/ContextAPI';
import Index from '../unhcr/facilityInternalRequest/Index';
import PermissionDenied from './PermissionDenied';

export default function FacilityInternalRequestUnhcr() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['facility-internal-request-unhcr-init']) return <PermissionDenied />;

    return (
        <ApprovalProvider>
            <Index />
        </ApprovalProvider>
    );
}

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Badge, Button, Col, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import CommonApiService from '../../../services/CommonApiService';
import { useD2Form } from './ContextAPI';
import RequisitionShowTable from './RequisitionShowTable';

export default function RequisitionModalInfo() {
    const { show, setShow, rowData } = useD2Form();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);

    console.log({ rowData });

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <RequisitionShowTable
                    data={rowData?.records?.filter((el) => el.category_id == item.id)}
                />
            </Tab>
        ));

    return (
        <Modal className="modalWidth80" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Facility Requisition</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Table bordered hover size="sm">
                            <thead className="table-light">
                                <tr>
                                    <th>Facility Name</th>
                                    <th>Stock Status</th>
                                    <th>Submitted Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{rowData?.facility_name}</td>
                                    <td>
                                        {rowData.stock_status === 'approved' ? (
                                            <Badge className="badgeActive" bg="success">
                                                Approved
                                            </Badge>
                                        ) : rowData.stock_status === 'd2_prepared' ? (
                                            <Badge className="badgeInactive" bg="success">
                                                D2 Prepared
                                            </Badge>
                                        ) : (
                                            <Badge className="badgeInactive" bg="success">
                                                Pending
                                            </Badge>
                                        )}
                                    </td>
                                    <td>{moment(rowData?.submitted_date).format('DD MMM YYYY')}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Tabs
                        activeKey={page}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                        // eslint-disable-next-line radix
                        onSelect={(k) => setPage(parseInt(k))}
                    >
                        {tableDataDisplay()}
                    </Tabs>
                </Row>

                <Modal.Footer className="borderTop0 pe-0">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="btnInfo me-2 ms-2"
                        variant="primary"
                        style={{ minWidth: '6rem' }}
                        disabled={page === 1}
                        onClick={() => {
                            setPage((currPage) => currPage - 1);
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        type="button"
                        className="btnSuccess"
                        disabled={page === pageLength}
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        onClick={() => setPage((currPage) => currPage + 1)}
                    >
                        Next
                    </Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
}

/* eslint-disable radix */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useFacilityStockDistribution } from './ContextAPI';
import RequisitionTable from './RequisitionTable';

export default function FormInfo() {
    const { auth } = useAuth();
    const facilityId = auth?.employee_info?.facility_id;

    const { setFormShow, rowData, requisitionList } = useFacilityStockDistribution();
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);
    const [dispensaryData, setDispensaryData] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { drugCategory, getDrugCategory, dispensaryByFacility, getDispensaryByFacility } =
        CommonApiService();
    const axiosPrivate = useAxiosPrivate();

    const {
        register,
        formState: { errors },
        reset,
        control,
        handleSubmit,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        if (!isEmpty(requisitionList)) {
            setValue('requisition', requisitionList);
        }
    }, [requisitionList]);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
        getDispensaryByFacility(facilityId);
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    useEffect(() => {
        setDispensaryData(
            dispensaryByFacility?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [dispensaryByFacility]);

    // console.log(dispensaryByFacility);

    const tableDataDisplay = useCallback(
        () =>
            drugCategory?.map((item, index) => (
                <Tab key={item.id} eventKey={index} title={item.name}>
                    <RequisitionTable id={item.id} />
                </Tab>
            )),
        [drugCategory]
    );

    // const tableDataDisplay = () =>
    //     drugCategory?.map((item) => (
    //         <Tab key={item.id} eventKey={item.id} title={item.name}>
    //             <RequisitionTable id={item.id} />
    //         </Tab>
    //     ));

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            distribution_type_id: rowData.d1_master_id,
            distribution_type: 'normal',
            dispensary_id: data.dispensary_id.value,
            date: data.date.toLocaleDateString('sv-SE'),
        };

        try {
            await axiosPrivate.post(UrlService.facilityStockOut(), JSON.stringify(formData), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            ToastifyService.updateNotify('D2 Approve Successfull');
            setFormShow(false);
            reset();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setFormShow(false);
    };

    register('requisition', { required: 'Requisition quantity is required!' });

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Facility Stock Distribution</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <fieldset className="customFieldset dark-light-bg p-4 mb-4">
                    <Row>
                        <Col lg={8}>
                            <Form.Group className="mb-3">
                                <Form.Label>Department</Form.Label>
                                <Controller
                                    name="dispensary_id"
                                    id="dispensary_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={
                                                errors?.dispensary_id && 'invalidInput'
                                            }
                                            options={dispensaryData}
                                        />
                                    )}
                                    rules={{
                                        required: 'Please select your dispensary.',
                                    }}
                                />
                                {errors.dispensary_id && (
                                    <span className="invalid">{errors.dispensary_id?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="pe-1">
                            <Form.Group className="mb-3">
                                <Form.Label className="pe-5">Date</Form.Label>
                                <Controller
                                    control={control}
                                    name="date"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            closeOnScroll
                                            placeholderText="Select date"
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            className={
                                                errors?.date
                                                    ? 'invalidInput form-control'
                                                    : 'form-control'
                                            }
                                            popperPlacement="top-end"
                                            // minDate={new Date()}
                                        />
                                    )}
                                    rules={{
                                        required: 'Please select your date.',
                                        valueAsDate: true,
                                    }}
                                />
                                {errors.date && (
                                    <span className="invalid">{errors.date?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>
                <Row>
                    <Tabs
                        activeKey={page}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 ps-3"
                        onSelect={(k) => setPage(parseInt(k))}
                    >
                        {tableDataDisplay()}
                    </Tabs>
                </Row>

                {errors.requisition && (
                    <span className="invalid">{errors.requisition?.message}</span>
                )}

                <div className="formFooter">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="btnInfo me-2 ms-2"
                        variant="primary"
                        style={{ minWidth: '6rem' }}
                        disabled={page === 1}
                        onClick={() => {
                            setPage((currPage) => currPage - 1);
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        type="button"
                        className={`btnSuccess ${page === pageLength && 'd-none'}`}
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        onClick={() => setPage((currPage) => currPage + 1)}
                    >
                        Next
                    </Button>

                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className={`btnSuccess ${page !== pageLength && 'd-none'}`}
                        type="button"
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        disabled={isSubmitted}
                    >
                        Distribution
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}

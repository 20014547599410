/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { isEmpty, toArray } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useApproval } from './ContextAPI';
import RequisitionTable from './RequisitionTable';

export default function FormInfo() {
    const {
        setFormShow,
        rowData,
        receiveData,
        setReceiveData,
        receiveList,
        setReceiveList,
        getPendingData,
        getApprovedData,
        detailsData,
        setDetailsData,
    } = useApproval();

    const { centerWarehouse, getCenterWarehouse } = CommonApiService();
    const [warehouseData, setWarehouseData] = useState();

    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const dataList = useCallback(() => {
        getCenterWarehouse(); // Center Warehouse
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setWarehouseData(
            centerWarehouse?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [centerWarehouse]);

    useEffect(() => {
        detailsData?.records?.map((el) => {
            const itemdata = {};
            itemdata.id = el.id;
            itemdata.drug_id = el.drug_id;
            itemdata.drug_name = el.drug_name;
            itemdata.category_id = el.category_id;
            itemdata.central_available_qty = el.central_available_qty;
            itemdata.approve_qty = el.approve_qty;
            itemdata.approved_qty = !Number(el.central_available_qty)
                ? 0
                : Number(el.central_available_qty) > Number(el.approve_qty)
                ? el.approve_qty
                : el.central_available_qty;

            const list = receiveData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReceiveData(list);

            const arrOfObj = toArray(list);
            setReceiveList(arrOfObj);
            return receiveData;
        });
    }, [detailsData.records]);

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        if (!isEmpty(receiveList)) {
            setValue('requisition', receiveList);
        }
    }, [receiveList]);

    const tableDataDisplay = () => <RequisitionTable data={detailsData?.records} />;

    const approveHandler = async (val) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getInternalRequestByIdAndCentralWarehousePartnerUrl(
                    rowData.master_id,
                    val
                ),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const details_info = {
                master_id: rowData.master_id,
                partner_name: rowData.partner_name,
                status: rowData.status,
                records: response?.data?.internal_request_details_info,
            };
            setDetailsData(details_info);
            setFormShow(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            submit_type: 'unhcr_received',
            master_id: rowData.master_id,
            central_warehouse_id: data.central_warehouse_id.value,
        };
        try {
            await axiosPrivate.post(
                UrlService.internalRequestApprovalPartnerUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            ToastifyService.updateNotify('Approve Successfull');
            setFormShow(false);
            reset();
            getPendingData();
            getApprovedData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setFormShow(false);
        setDetailsData('');
    };

    register('requisition', { required: 'Requisition quantity is required!' });

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Partner Internal Request</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <fieldset className="customFieldset p-4 mb-4">
                    <Form.Group>
                        <Row>
                            <Col lg={2}>
                                <Form.Label>Warehouse</Form.Label>
                            </Col>
                            <Col lg={4}>
                                <Controller
                                    name="central_warehouse_id"
                                    id="central_warehouse_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={
                                                errors?.central_warehouse_id && 'invalidInput'
                                            }
                                            options={warehouseData}
                                            onBlur={() => approveHandler(field?.value?.value)}
                                        />
                                    )}
                                    rules={{ required: 'Please select your warehouse.' }}
                                />
                                {errors.central_warehouse_id && (
                                    <span className="invalid">
                                        {errors.central_warehouse_id?.message}
                                    </span>
                                )}
                            </Col>

                            {!detailsData && (
                                <Col lg={2}>
                                    <Button
                                        type="button"
                                        className="btnSuccess}"
                                        variant="success"
                                        style={{ minWidth: '6rem' }}
                                    >
                                        Next
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Form.Group>
                </fieldset>

                {detailsData && (
                    <>
                        {tableDataDisplay()}

                        <div className="formFooter">
                            <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                                Close
                            </Button>

                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess ms-2"
                                type="button"
                                variant="success"
                                style={{ minWidth: '6rem' }}
                                disabled={isSubmitted}
                            >
                                Submit
                            </Button>
                        </div>
                    </>
                )}
            </Card.Body>
        </Card>
    );
}

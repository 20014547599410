/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Expired Medicine
 * Description: Expired Medicine Form: Create and Update
 * Date: 26/04/2022
 */

import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';
import CentralDataTable from './CentralDataTable';
import { useLowStock } from './ContextAPI';

export default function FormInfo() {
    const { setShow, setCentralInfo } = useLowStock();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const layer = auth?.employee_info?.layer_id;

    const d = new Date();
    d.setMonth(d.getMonth() - 34);
    const pDate = d;

    // Default Table Load Data
    useEffect(async () => {
        const data = {
            start_date: pDate.toLocaleDateString('sv-SE'),
            end_date: new Date().toLocaleDateString('sv-SE'),
            layer: 2,
        };
        try {
            const response = await axiosPrivate.post(
                UrlService.getAllNearlyExpiredMedicineUrl(layer),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setCentralInfo(response.data.drug_list);

            if (response?.data?.status === 'error') {
                // ToastifyService.dbErrorNotify(response?.data?.msg);
                // const message = Object.keys(response.data.msg);
                // message.forEach((key) => {
                //     response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                // });
                // ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShow(false);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    }, []);

    return (
        <Card>
            <Card.Header className="cardHeader" style={{ background: '#ff8100' }}>
                <h3 className="text-white">Central Warehouse: Items with low stock</h3>
            </Card.Header>
            <Card.Body>
                <div className="dataTableHeaderNone">
                    <CentralDataTable />
                </div>
            </Card.Body>
        </Card>
    );
}

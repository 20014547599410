/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/*
 * Author M. Atoar Rahman
 * Title: PO Wise Stock DataTable
 * Description: PO Wise Stock DataTable
 * Date: 15/03/2022
 */

import moment from 'moment';
import { Badge, Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { usePoStock } from './ContextAPI';

export default function DataTable() {
    const {
        stockData,
        setShowDetails,
        setPendingDetailsShow,
        setRowData,
        setShowPdfList,
        setShowList,
        setShowForm,
    } = usePoStock();
    const axiosPrivate = useAxiosPrivate();

    const handleShow = async (row) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getPoWiseStockItemByIdUrl(row.center_stock_in_id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const row_info = {
                status: '',
                po_no: row.po_no,
                po_date: row.po_date,
                supplier_name: row.supplier_name,
                total_supply_qty: row.supply_qty,
                total_receive_qty: row.receive_qty,
                records: response?.data?.stock_in_info,
            };
            setRowData(row_info);
            setShowDetails(true);
            setShowPdfList(false);
            setShowList(false);
            setShowForm(false);
            setPendingDetailsShow(false);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const handleShowPending = async (row) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getPoItemByIdUrl(row.purchase_order_id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const row_info = {
                status: 'pending',
                po_no: row.po_no,
                po_date: row.purchase_order_supplied_date,
                supplier_name: row.supplier_name,
                total_supply_qty: row.supply_qty,
                total_receive_qty: row.receive_qty,
                records: response?.data?.stock_in_info,
            };
            setRowData(row_info);
            setPendingDetailsShow(true);
            setShowDetails(false);
            setShowPdfList(false);
            setShowList(false);
            setShowForm(false);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const columns = [
        {
            name: 'PO No.',
            selector: (row) => row.po_no,
            sortable: true,
        },
        {
            name: 'PO Date',
            selector: (row) => row.po_date,
            format: (row) =>
                row.status === 'pending'
                    ? moment(row.purchase_order_supplied_date).format('DD MMM YYYY')
                    : moment(row.po_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Number of Items',
            selector: (row) =>
                row.status === 'pending'
                    ? row.purchase_order_number_of_items
                    : row.center_stock_in_number_of_items,
            sortable: true,
        },
        {
            name: 'Supplier Name',
            selector: (row) => row.supplier_name,
            sortable: true,
        },
        {
            name: 'Supplied Qty',
            selector: (row) => row.supply_qty,
            sortable: true,
        },
        {
            name: 'Received Qty',
            selector: (row) => row.receive_qty,
            sortable: true,
        },
        {
            name: 'Received Date',
            selector: (row) => row.received_date,
            format: (row) =>
                row.status === 'pending' ? '' : moment(row.received_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row) =>
                row.status === 'partial_received' ? (
                    <Badge className="badgeActive" bg="success">
                        Partial Received
                    </Badge>
                ) : row.status === 'received' ? (
                    <Badge className="badgeActive" bg="success">
                        Received
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    onClick={
                        row.status === 'pending'
                            ? () => handleShowPending(row)
                            : () => handleShow(row)
                    }
                    className="btnSuccess"
                    type="button"
                    variant="success"
                >
                    View
                </Button>
            ),
        },
    ];

    const search_column = {};
    return useDataTable({ columns, data: stockData, search_column });
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Badge } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';

export default function ShowTable({ data }) {
    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            grow: 3,
            fixed: true,
        },
        {
            name: 'Returned Qty',
            selector: (row) =>
                row.partner_d_return_approved_qty
                    ? row.partner_d_return_approved_qty
                    : row.partner_return_approved_qty,
            sortable: true,
        },
        {
            name: 'Received Qty',
            selector: (row) => row.central_received_qty,
            sortable: true,
        },
        {
            name: 'Receive Approved Qty',
            selector: (row) => row.central_receive_approved_qty,
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row) =>
                row.status === 'pending' ? (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ) : row.status === 'returned' ? (
                    <Badge className="badgeActive" bg="success">
                        Returned
                    </Badge>
                ) : row.status === 'received' ? (
                    <Badge className="badgeActive" bg="success">
                        Central Received
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Invalid Status
                    </Badge>
                ),
        },
    ];

    const search_column = {
        // drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Requisition DataTable
 * Description: Requisition DataTable
 * Date: 05/06/2022
 */

import { Badge } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';

export default function RequisitionTable({ data }) {
    const conditionalCellStyles = [
        {
            when: (row) => Number(row.central_available_qty) <= 0,
            style: {
                backgroundColor: '#ffb4c1',
            },
        },
    ];

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            conditionalCellStyles,
            wrap: true,
            grow: 2,
            fixed: true,
        },
        {
            name: 'Category Name',
            selector: (row) => row.category_name,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Status',
            sortable: true,
            conditionalCellStyles,
            cell: (row) =>
                row.status === 'distributed' ? (
                    <Badge className="badgeActive" bg="success">
                        Distributed
                    </Badge>
                ) : row.status === 'forwarded' ? (
                    <Badge className="badgeActive" bg="success">
                        Forwarded
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Facility Requested Qty',
            selector: (row) => row.facility_approved_qty,
            sortable: true,
        },
        {
            name: 'Partner Distribute Qty',
            selector: (row) => row.partner_distribute_apv_qty,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Facility Received Qty',
            selector: (row) => row.facility_received_qty,
            sortable: true,
        },
        {
            name: 'Facility Rejected Qty',
            selector: (row) => row.facility_rejected_qty,
            sortable: true,
        },
        {
            name: 'Facility Rejected Reason',
            selector: (row) => row.facility_reject_reason,
            sortable: true,
        },
    ];

    const search_column = {
        // drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Center Stockin DataTable
 * Description: Center Stockin DataTable
 * Date: 26/04/2022
 */

import moment from 'moment';
import { Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { useCurrentStockEntry } from './ContextAPI';

export default function PartnerDataTable() {
    const { stockData, setShow, setRowData } = useCurrentStockEntry();
    const axiosPrivate = useAxiosPrivate();

    const editHander = async (row) => {
        try {
            const response = await axiosPrivate.post(
                UrlService.getEditDataCurrentStockEntryUrl(),
                JSON.stringify(row),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const stock_in_info = response?.data?.stock_in_info[0];

            setShow(true);
            setRowData({ ...stock_in_info });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const columns = [
        {
            name: 'GRN No',
            selector: (row) => row.grn_no,
            sortable: true,
        },
        {
            name: 'Entry Date',
            selector: (row) => row.entry_date,
            format: (row) => (row.entry_date ? moment(row.entry_date).format('DD MMM YYYY') : ''),
            sortable: true,
        },
        {
            name: 'PO No',
            selector: (row) => row.po_no,
            sortable: true,
        },
        {
            name: 'PO Date',
            selector: (row) => row.po_date,
            format: (row) => (row.po_date ? moment(row.po_date).format('DD MMM YYYY') : ''),
            sortable: true,
        },
        {
            name: 'Waybill No',
            selector: (row) => row.waybill_no,
            sortable: true,
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    className="btn btn-light actionBtn"
                    variant="primary"
                    onClick={() => editHander(row)}
                >
                    <i className="fa fa-edit" />
                </Button>
            ),
        },
    ];

    const search_column = {
        // grn_no: 'GRN No',
        // entry_date: 'Entry Date',
        // po_no: 'PO No',
        // po_date: 'PO Date',
        // waybill_no: 'Waybill No',
    };
    return useDataTable({
        columns,
        data: stockData,
        search_column,
    });
}

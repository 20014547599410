/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: PO Wise Stock
 * Description: PO Wise Stock Form: Create and Update
 * Date: 26/04/2022
 */

import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { usePoStock } from './ContextAPI';

export default function FormInfo() {
    const { setShowForm, setShowList, setStockData } = usePoStock();
    const axiosPrivate = useAxiosPrivate();

    const {
        formState: { errors },
        reset,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const formSubmitHandler = async (data) => {
        const formData = {
            start_date: data.date_from.toLocaleDateString('sv-SE'),
            end_date: data.date_to.toLocaleDateString('sv-SE'),
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.getAllPoWiseStockReceiveUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setStockData(response?.data?.po_wise_stock_in_info);

            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.message);
                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShowList(true);
                setShowForm(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>PO Wise Stock Receive Status</h3>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Row>
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>PO Date From</Form.Label>

                                <Controller
                                    control={control}
                                    name="date_from"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            closeOnScroll
                                            placeholderText="Select date"
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            className="form-control"
                                            popperPlacement="top-end"
                                            // minDate={new Date()}
                                        />
                                    )}
                                    rules={{
                                        required: 'Date is required.',
                                        valueAsDate: true,
                                    }}
                                />
                                {errors.date_from && (
                                    <span className="invalid">{errors.date_from?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>PO Date to</Form.Label>

                                <Controller
                                    control={control}
                                    name="date_to"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            closeOnScroll
                                            placeholderText="Select date"
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            className="form-control"
                                            popperPlacement="top-end"
                                            // minDate={new Date()}
                                        />
                                    )}
                                    rules={{
                                        required: 'Date is required.',
                                        valueAsDate: true,
                                    }}
                                />
                                {errors.date_to && (
                                    <span className="invalid">{errors.date_to?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mt-3 pt-1">
                            <Button
                                className="btnSuccess"
                                onClick={handleSubmit(formSubmitHandler)}
                                type="button"
                                variant="success"
                            >
                                View Stock
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title:Purchase Order
 * Description:Purchase Order Form: Create and Update
 * Date: 26/04/2022
 */

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { usePoReports } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { setShow, setData, setListShow, setDateRange } = usePoReports();
    const axiosPrivate = useAxiosPrivate();

    // Local State
    const [poData, setPoData] = useState();
    // const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const { purchaseOrder, getPurchaseOrder } = CommonApiService();

    const dataList = useCallback(() => {
        getPurchaseOrder();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        setPoData(
            purchaseOrder?.map((el) => ({
                value: el.id,
                label: el.purshase_order_no,
            }))
        );
    }, [purchaseOrder]);

    const [checked, setChecked] = useState('');

    const handleCentralCurrentStock = () => {
        reset();
        // resetField();

        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };

    const formSubmitHandler = async (data) => {
        const formData = {
            start_date: data?.date_from?.toLocaleDateString('sv-SE'),
            end_date: data?.date_to?.toLocaleDateString('sv-SE'),
            po_id: data?.po_id?.value,
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.getPoReportsUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setData(response.data.request_info);
            setDateRange(response.data.date_range);
            setListShow(true);
            if (response?.data?.status === 'success' && response.data.report_info.length === 0) {
                ToastifyService.dbErrorNotify(response?.data?.msg);
            }
            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.msg);
                const message = Object.keys(response.data.msg);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.msg);
            } else {
                setShow(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Form>
            <Row>
                <Col>
                    <Form.Group className="mb-3 d-flex lign-items-center">
                        <Form.Check
                            name="report_with_po"
                            id="user-status-switch"
                            type="switch"
                            checked={checked}
                            onClick={() => {
                                handleCentralCurrentStock();
                            }}
                            onChange={() => {
                                handleCentralCurrentStock();
                            }}
                            {...register('report_with_po')}
                        />
                        <span className="mt-1">Report with Purchase Order</span>
                    </Form.Group>
                </Col>
                <hr />
                {checked ? (
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Purchase Order<span className="invalid">*</span>
                            </Form.Label>
                            <Controller
                                name="po_id"
                                id="po_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.po_id && 'invalidInput'}
                                        options={poData}
                                    />
                                )}
                                rules={{
                                    required: 'Please select the PO.',
                                }}
                            />
                            {errors.po_id && (
                                <span className="invalid">{errors.po_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                ) : (
                    <>
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Date From<span className="invalid">*</span>
                                </Form.Label>

                                <Controller
                                    control={control}
                                    name="date_from"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            closeOnScroll
                                            placeholderText="Select date"
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            className="form-control"
                                            popperPlacement="top-end"
                                            // minDate={new Date()}
                                        />
                                    )}
                                    rules={{
                                        required: 'Date is required.',
                                        valueAsDate: true,
                                    }}
                                />
                                {errors.date_from && (
                                    <span className="invalid">{errors.date_from?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Date to<span className="invalid">*</span>
                                </Form.Label>

                                <Controller
                                    control={control}
                                    name="date_to"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            closeOnScroll
                                            placeholderText="Select date"
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            className="form-control"
                                            popperPlacement="top-end"
                                            // minDate={new Date()}
                                        />
                                    )}
                                    rules={{
                                        required: 'Date is required.',
                                        valueAsDate: true,
                                    }}
                                />
                                {errors.date_to && (
                                    <span className="invalid">{errors.date_to?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                    </>
                )}
            </Row>
            <Row>
                <Form.Group className="mb-3">
                    <Row className="text-end">
                        <Col lg={12}>
                            <Button
                                className="btnSuccess"
                                onClick={handleSubmit(formSubmitHandler)}
                                type="button"
                                variant="success"
                                // disabled={isSubmitted}
                            >
                                View Report
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
        </Form>
    );
}

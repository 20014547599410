/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Internal Request Context
 * Description: Page for Internal Request Context
 * Date: 30/05/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Create Context
const ContextAPI = createContext();

export function useApproval() {
    return useContext(ContextAPI);
}

// Provider Component
export function ApprovalProvider({ children }) {
    const [show, setShow] = useState(false);
    const [formShow, setFormShow] = useState(false);
    const [rowData, setRowData] = useState('');
    const [pendingData, setPendingData] = useState();
    const [approvedData, setApprovedData] = useState();
    const [receiveData, setReceiveData] = useState({});
    const [receiveList, setReceiveList] = useState({});

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data  All Data
    const getPendingData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllInternalRrequestUrl('center_received', 1),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setPendingData(response?.data?.internal_request_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getApprovedData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllInternalRrequestUrl('center_distributed', 1),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setApprovedData(response?.data?.internal_request_info);
            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                rowData,
                setRowData,
                formShow,
                setFormShow,
                pendingData,
                getPendingData,
                approvedData,
                getApprovedData,
                receiveData,
                setReceiveData,
                receiveList,
                setReceiveList,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Pending Internal Request Table
 * Description: Pending Internal Request Table
 * Date: 05/06/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
// import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
// import UrlService from '../../../services/UrlService';
import { useApproval } from './ContextAPI';

export default function PendingTable() {
    const { setFormShow, setRowData, pendingData, getPendingData } = useApproval();
    // const axiosPrivate = useAxiosPrivate();

    const approveHandler = async (row) => {
        const row_info = {
            master_id: row.id,
            partner_name: row.partner_name,
            facility_name: row.facility_name,
            status: row.status,
        };
        setRowData(row_info);
        setFormShow(true);
    };

    const dataList = useCallback(() => {
        getPendingData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const columns = [
        {
            name: 'Facility',
            selector: (row) => row.facility_name,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Submitted Date',
            selector: (row) => row.approve_date,
            format: (row) =>
                row.approve_date ? moment(row.approve_date).format('DD MMM YYYY') : '',
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: () => (
                <Badge className="badgeInactive" bg="success">
                    Pending
                </Badge>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    onClick={() => approveHandler(row)}
                    className="btnSuccess"
                    type="button"
                    variant="success"
                >
                    View
                </Button>
            ),
        },
    ];

    const search_column = {
        // partner_name: 'Partner',
        // submited_date: 'Submited Date',
    };
    return useDataTable({ columns, data: pendingData, search_column, pagination: false });
}

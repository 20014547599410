/* eslint-disable no-unused-vars */
/*
 * Author M. Atoar Rahman
 * Title: MenuList
 * Description: Menu and Sub-Menu Name: Add, Edit, Delete
 * Date: 15/03/2022
 */

import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/logo.png';
import { useAuth } from '../../../contexts/AuthContext';
// import { briefcase, dashboard, drug, settings, square, star, user, utility } from '../../SvgIcon';
import MenuItem from './MenuItem';

// const MenuList = [
//     {
//         name: 'Dashboard',
//         path: '/',
//         icon: dashboard,
//     },
//     {
//         name: 'Menu Management',
//         icon: user,
//         children: [
//             {
//                 name: 'Menu Info',
//                 path: '/menu-management/menu-info',
//                 icon: star,
//             },
//         ],
//     },
//     {
//         name: 'User Management',
//         icon: user,
//         children: [
//             {
//                 name: 'User Info',
//                 path: '/user-info',
//                 icon: star,
//             },
//             {
//                 name: 'Employee Info',
//                 path: '/employee-info',
//                 icon: star,
//             },
//             {
//                 name: 'Role Info',
//                 path: '/permission-management/role-info',
//                 icon: star,
//             },
//             {
//                 name: 'Menu Permission',
//                 path: '/permission-management/role-permission',
//                 icon: star,
//             },
//         ],
//     },
//     {
//         name: 'System Master setup',
//         icon: settings,
//         children: [
//             {
//                 name: 'Partner Information',
//                 path: '/partner-info',
//                 icon: star,
//             },
//             {
//                 name: 'Facility Infomation',
//                 path: '/facility-info',
//                 icon: star,
//             },
//             {
//                 name: 'Dispensary Info',
//                 path: '/dispensary',
//                 icon: star,
//             },
//             {
//                 name: 'Country',
//                 path: '/country',
//                 icon: star,
//             },
//             {
//                 name: 'Therapeutic Action Class',
//                 path: '/therapeutic-action',
//                 icon: star,
//             },
//             {
//                 name: 'Generic',
//                 path: '/drug-generic',
//                 icon: star,
//             },
//             {
//                 name: 'Group',
//                 path: '/drug-group',
//                 icon: star,
//             },
//             {
//                 name: 'Manufacturer',
//                 path: '/drug-manufacturer',
//                 icon: star,
//             },
//             {
//                 name: 'Supplier',
//                 path: '/drug-supplier',
//                 icon: star,
//             },
//             {
//                 name: 'Presentation Type',
//                 path: '/drug-presentation-type',
//                 icon: star,
//             },
//             {
//                 name: 'Presentation Strength',
//                 path: '/drug-presentation-strength',
//                 icon: star,
//             },
//             {
//                 name: 'Drug Category',
//                 path: '/drug-category',
//                 icon: star,
//             },
//             {
//                 name: 'Drug Information',
//                 path: '/drug-info',
//                 icon: star,
//             },
//         ],
//     },
//     {
//         name: 'UNHCR',
//         icon: square,
//         children: [
//             {
//                 name: 'Approval setup',
//                 path: '/unhcr/approval-setup',
//                 icon: star,
//             },
//             {
//                 name: 'GRN Approval',
//                 path: '/grn-approval',
//                 icon: star,
//             },
//             {
//                 name: 'D2 Approval',
//                 path: '/d2-approval',
//                 icon: star,
//             },
//             {
//                 name: 'D3 Approval',
//                 path: '/d3-approval',
//                 icon: star,
//             },
//             {
//                 name: 'D4 Approval',
//                 path: '/d4-approval',
//                 icon: star,
//             },
//             {
//                 name: 'D6 Approval',
//                 path: '/d6-approval',
//                 icon: star,
//             },
//             {
//                 name: 'Internal Req. APV',
//                 path: '/internal-request-approval',
//                 icon: star,
//             },
//             {
//                 name: 'Overstock APV',
//                 path: '/overstock-approval',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Return APV',
//                 path: '/stock-return-approval',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Destruction APV',
//                 path: '/stock-destruction-approval',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Donation APV',
//                 path: '/stock-donation-approval',
//                 icon: star,
//             },
//             {
//                 name: 'MIS Report',
//                 path: '/mis-report',
//                 icon: star,
//             },
//         ],
//     },
//     {
//         name: 'Center Warehouse',
//         icon: utility,
//         children: [
//             {
//                 name: 'Approval Setup',
//                 path: '/center-warehouse/approval-setup',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Opening Balance',
//                 path: '/stock-opening-balance',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Closing Balance',
//                 path: '/stock-closing-balance',
//                 icon: star,
//             },
//             {
//                 name: 'Stock In',
//                 path: '/stock-in',
//                 icon: star,
//             },
//             {
//                 name: 'D3 Preparation',
//                 path: '/d3-preparation',
//                 icon: star,
//             },
//             {
//                 name: 'D4 Preparation',
//                 path: '/d4-preparation',
//                 icon: star,
//             },
//             {
//                 name: 'D6 Preparation',
//                 path: '/d6-preparation',
//                 icon: star,
//             },
//             {
//                 name: 'D2 Distribution',
//                 path: '/stock-distribution',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Donation',
//                 path: '/stock-donation',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Destruction',
//                 path: '/stock-destruction',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Loss',
//                 path: '/stock-loss',
//                 icon: star,
//             },
//             {
//                 name: 'Information',
//                 path: '/warehouse-info',
//                 icon: star,
//             },
//             {
//                 name: 'Center Stock In',
//                 path: '/center-stock-in',
//                 icon: star,
//             },
//             {
//                 name: 'MIS Report',
//                 path: '/mis-report',
//                 icon: star,
//             },
//         ],
//     },
//     {
//         name: 'Partner - Facility',
//         icon: drug,
//         children: [
//             {
//                 name: 'Approval Setup',
//                 path: '/partner-facility/approval-setup',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Opening Balance',
//                 path: '/stock-opening-balance',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Closing Balance',
//                 path: '/stock-closing-balance',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Receive',
//                 path: '/stock-receive',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Distribution',
//                 path: '/stock-distribution',
//                 icon: star,
//             },
//             {
//                 name: 'Stock In',
//                 path: '/stock-in',
//                 icon: star,
//             },
//             {
//                 name: 'D1 Preparation',
//                 path: '/d1-preparation',
//                 icon: star,
//             },
//             {
//                 name: 'D2 Preparation',
//                 path: '/d2-preparation',
//                 icon: star,
//             },
//             {
//                 name: 'Facility Int. Req',
//                 path: '/facility-internal-request',
//                 icon: star,
//             },
//             {
//                 name: 'Partner Int. Req',
//                 path: '/partner-internal-request',
//                 icon: star,
//             },
//             {
//                 name: 'Facility Int. Req. APV',
//                 path: '/facility-internal-request-approval',
//                 icon: star,
//             },
//             {
//                 name: 'Dispensary Int. Req. APV',
//                 path: '/dispensary-internal-request-approval',
//                 icon: star,
//             },
//             {
//                 name: 'Overstock',
//                 path: '/overstock',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Return',
//                 path: '/stock-return',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Destruction',
//                 path: '/stock-destruction',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Loss',
//                 path: '/stock-loss',
//                 icon: star,
//             },
//             {
//                 name: 'MIS Report',
//                 path: '/mis-report',
//                 icon: star,
//             },
//         ],
//     },
//     {
//         name: 'Dispensary',
//         icon: briefcase,
//         children: [
//             {
//                 name: 'Stock Opening Balance',
//                 path: '/stock-opening-balance',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Closing Balance',
//                 path: '/stock-closing-balance',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Receive',
//                 path: '/stock-receive',
//                 icon: star,
//             },
//             {
//                 name: 'Item Dispatch',
//                 path: '/item-dispatch',
//                 icon: star,
//             },
//             {
//                 name: 'Consumption Tally',
//                 path: '/consumption-tally',
//                 icon: star,
//             },
//             {
//                 name: 'Internal Request',
//                 path: '/internal-request',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Return',
//                 path: '/stock-return',
//                 icon: star,
//             },
//             {
//                 name: 'Stock Loss',
//                 path: '/stock-loss',
//                 icon: star,
//             },
//         ],
//     },
// ];

export default function MenuSidebar() {
    const { auth } = useAuth();
    const MenuList = auth.menus;
    if (typeof MenuList === 'undefined') {
        return '';
    }
    return (
        <aside className="main-sidebar sidebar-dark-primary">
            <NavLink exact="true" to="/" className="brand-link">
                <img src={logo} alt="Logo" className="brand-image" />
                <h1 className="brand-text">
                    UNHCR
                    <br />
                    <span>The UN Refugee Agency</span>
                </h1>
            </NavLink>
            <div className="sidebar">
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar" role="menu">
                        {MenuList.map((item) => (
                            <MenuItem key={Math.random()} menuItem={item} />
                        ))}

                        {/* Remove It  */}
                        <li className="nav-item ">
                            <NavLink
                                className="nav-link "
                                to="/current-stock-entry"
                                style={{
                                    cursor: 'pointer',
                                    border: '1px solid red',
                                }}
                            >
                                Current Stock Entry
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="asideFooter">
                <h4>N-PMS 1.0.0</h4>
                <p>Developed &amp; Maintanence by Nanosoft</p>
            </div>
        </aside>
    );
}

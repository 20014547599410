/* eslint-disable react-hooks/exhaustive-deps */
import { Badge } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';

export default function ShowTable({ data }) {
    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            grow: 3,
        },
        {
            name: 'UNHCR Requested',
            selector: (row) => row.unhcr_request_apv_qty,
            center: true,
            sortable: true,
        },
        {
            name: 'Partner Returned',
            selector: (row) => row.partner_return_approved_qty,
            center: true,
            sortable: true,
        },
        {
            name: 'Facility Returned',
            selector: (row) => row.facility_returned_qty,
            center: true,
            sortable: true,
        },
        {
            name: 'Facility Returned Apv',
            selector: (row) => row.facility_return_approved_qty,
            center: true,
            sortable: true,
        },
        {
            name: 'Central Received',
            selector: (row) => row.central_receive_approved_qty,
            center: true,
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row) =>
                row.status === 'pending' ? (
                    <Badge className="badgeInactive" bg="success">
                        Pending
                    </Badge>
                ) : row.status === 'returned' ? (
                    <Badge className="badgeActive" bg="success">
                        Returned
                    </Badge>
                ) : row.status === 'received' ? (
                    <Badge className="badgeActive" bg="success">
                        Received
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Invalid Status
                    </Badge>
                ),
        },
    ];

    const search_column = {
        // drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column });
}

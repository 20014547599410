/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Internal Request Approve
 * Description: Dispensary Internal Request Approve Form: Create and Update
 * Date: 26/04/2022
 */

import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import CommonApiService from '../../../services/CommonApiService';
import ApproveTable from './ApproveTable';
import { useInternalRequest } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { setShow, addInternalRequest, editInternalRequest, approveList, rowData } =
        useInternalRequest();

    // Local State
    const [partnerData, setPartnerData] = useState([]);
    const [facilityData, setFacilityData] = useState([]);
    const [dispensaryData, setDispensaryData] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    // const [itemDetailsRowData, setItemDetailsRowData] = useState({});

    const {
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        dispensaryByFacility,
        getDispensaryByFacility,
    } = CommonApiService();

    const {
        register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
        setValue,
        // watch,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                partner_id: rowData && {
                    label: rowData?.partner_name,
                    value: rowData?.partner_id,
                },
                facility_id: rowData && {
                    label: rowData?.facility_name,
                    value: rowData?.facility_id,
                },
                dispensary_id: rowData && {
                    label: rowData?.dispensary_name,
                    value: rowData?.dispensary_id,
                },
                approved_date: rowData?.approve_date && new Date(rowData.approve_date),
            }),
            [rowData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            partner_id: rowData && {
                label: rowData?.partner_name,
                value: rowData?.partner_id,
            },
            facility_id: rowData && {
                label: rowData?.facility_name,
                value: rowData?.facility_id,
            },
            dispensary_id: rowData && {
                label: rowData?.dispensary_name,
                value: rowData?.dispensary_id,
            },
            approved_date: rowData?.approve_date && new Date(rowData.approve_date),
        });
    }, [rowData]);

    // Set default values
    useEffect(() => {
        if (!isEmpty(approveList)) {
            setValue('requisition', approveList);
        }
    }, [approveList]);

    const dataList = useCallback(() => {
        getPartner(); // Partner
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Dispensary
        setDispensaryData(
            dispensaryByFacility?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner, facilityByPartner, dispensaryByFacility]);

    // React.useEffect(() => {
    //     const subscription = watch((value, { name, type }) => console.log(value, name, type));
    //     return () => subscription.unsubscribe();
    // }, [watch]);

    // Form Close
    const handleClose = () => {
        setShow(false);
    };

    register('requisition', { required: 'Approve quantity is required!' });

    // Form Submit Handelar
    const formSubmitHandler = (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            dispensary_request_id: rowData.id,
            partner_id: data.partner_id.value,
            facility_id: data.facility_id.value,
            dispensary_id: data.dispensary_id.value,
            date: data.approved_date.toLocaleDateString('sv-SE'),
            distribution_type: 'internal_request',
            request_mode: rowData.request_mode,
        };
        if (editData) {
            editInternalRequest(formData, editData.id); // Update Data
        } else {
            addInternalRequest(formData); // Create Data
        }
        setShow(false);
        reset();
    };

    return (
        <Form>
            <fieldset style={{ background: '#f5f7ff' }} className="customFieldset p-3 pb-0 mb-3">
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Partner</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        name="partner_id"
                                        id="partner_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.partner_id && 'invalidInput'
                                                }
                                                options={partnerData}
                                                isDisabled={rowData?.partner_id}
                                                onBlur={async () => {
                                                    await resetField('facility_id');
                                                    await resetField('dispensary_id');
                                                    await getFacilityByPartner(field.value.value);
                                                }}
                                            />
                                        )}
                                        rules={{ required: 'Please select your partner.' }}
                                    />
                                    {errors?.partner_id && (
                                        <span className="invalid">
                                            {errors?.partner_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Dispensary</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        name="dispensary_id"
                                        id="dispensary_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.dispensary_id && 'invalidInput'
                                                }
                                                options={dispensaryData}
                                                isDisabled={rowData?.dispensary_id}
                                            />
                                        )}
                                        rules={{ required: 'Please select your dispensary.' }}
                                    />
                                    {errors?.dispensary_id && (
                                        <span className="invalid">
                                            {errors?.dispensary_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Facility</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        name="facility_id"
                                        id="facility_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.facility_id && 'invalidInput'
                                                }
                                                options={facilityData}
                                                isDisabled={rowData?.facility_id}
                                                onBlur={async () => {
                                                    await resetField('dispensary_id');
                                                    await getDispensaryByFacility(
                                                        field.value.value
                                                    );
                                                }}
                                            />
                                        )}
                                        rules={{ required: 'Please select your facility.' }}
                                    />
                                    {errors?.facility_id && (
                                        <span className="invalid">
                                            {errors?.facility_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Date <span className="text-danger">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        control={control}
                                        name="approved_date"
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                closeOnScroll
                                                placeholderText="Select date"
                                                dateFormat="dd-MM-yyyy"
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                className="form-control"
                                                popperPlacement="top-end"
                                                minDate={new Date()}
                                            />
                                        )}
                                        rules={{
                                            required: 'Please select your date.',
                                            valueAsDate: true,
                                        }}
                                    />
                                    {errors.approved_date && (
                                        <span className="invalid">
                                            {errors.approved_date?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>

            <Row className="requisition_table">
                <ApproveTable />
            </Row>

            {errors.requisition && <span className="invalid">{errors.requisition?.message}</span>}

            <div className="formFooter">
                <Button className="btnCancel me-2" variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {rowData.status == 0 && (
                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className="btnSuccess"
                        type="button"
                        variant="success"
                        disabled={rowData.status != 0 || isSubmitted}
                    >
                        Submit
                    </Button>
                )}
            </div>
        </Form>
    );
}

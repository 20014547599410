/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Facility Internal Request Approve
 * Description: Facility Internal Request Approve
 * Date: 30/06/2022
 */

import { toArray } from 'lodash';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useInternalRequest } from './ContextAPI';

export default function ApproveTable() {
    const { approveData, setApproveData, setApproveList, rowData } = useInternalRequest();

    // const approveRowHandler = (value, item) => {
    //     const itemdata = item;
    //     itemdata.approve_qty = value;
    //     const list = { ...approveList };
    //     list[itemdata.code_no] = itemdata;
    //     if (value === '') {
    //         delete list[itemdata.code_no];
    //     }
    //     const arrOfObj = toArray(list);

    //     setApproveList(arrOfObj);
    // };

    const approveRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.approve_qty = value;
        const list = { ...approveData };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        setApproveData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(approveData);
        setApproveList(arrOfObj);
    }, [approveData]);

    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Request Qty',
            selector: (row) => row.req_qty,
            sortable: true,
        },
        {
            name: 'Approve Qty',
            selector: (row) => row.approve_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        approveRowHandler(e.target.value, row);
                    }}
                    defaultValue={approveData?.[row.drug_id]?.approve_qty}
                />
            ),
        },
        {
            name: 'Remark',
            selector: (row) => row.req_remark,
            sortable: true,
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data: rowData.items, search_column, pagination: false });
}

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import CommonApiService from '../../../services/CommonApiService';
import { useD2Approval } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModel() {
    const {
        show,
        setShow,
        rowData,
        addD2FinalApproval,
        receiveData,
        setReceiveData,
        receiveList,
        // setReceiveList,
    } = useD2Approval();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    // console.log({ rowData });
    useEffect(() => {
        rowData?.records?.map((el) => {
            const itemdata = {};
            itemdata.drug_id = el.drug_id;
            itemdata.drug_name = el.drug_name;
            itemdata.d2_details_id = el.d2_details_id;
            itemdata.central_warehouse_qty = el.central_warehouse_qty;
            itemdata.batch_no = el.batch_no;
            itemdata.category_id = el.category_id;
            itemdata.requested_qty = el.requested_qty;
            itemdata.approved_qty = el.approved_qty;
            itemdata.final_approved_qty =
                Number(el.central_warehouse_qty) > Number(el.requested_qty)
                    ? el.approved_qty
                    : el.central_warehouse_qty;
            // itemdata.final_approved_qty =
            //     el.final_approved_qty > 0 ? el.final_approved_qty : el.approved_qty;

            const list = receiveData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReceiveData(list);

            // const arrOfObj = toArray(list);
            // setReceiveList(arrOfObj);
            return receiveData;
        });
    }, [rowData.records]);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <ShowTable data={rowData?.records?.filter((el) => el.category_id == item.id)} />
            </Tab>
        ));

    const submitHandler = () => {
        setIsSubmitted(true);

        const data = {
            d2_master_id: rowData.d2_master_id,
            medicine_lists: receiveList,
        };
        addD2FinalApproval(data);
    };

    return (
        <Modal className="modalWidth80" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Partner Internal Request Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col className="p-0">
                            <Table bordered hover size="sm">
                                <thead className="table-light">
                                    <tr>
                                        <th>Partner Name</th>
                                        <th>Month</th>
                                        <th>Year</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{rowData?.partner_name}</td>
                                        <td>
                                            {rowData?.month == 1
                                                ? 'January'
                                                : rowData?.month == 2
                                                ? 'February'
                                                : rowData?.month == 3
                                                ? 'March'
                                                : rowData?.month == 4
                                                ? 'April'
                                                : rowData?.month == 5
                                                ? 'May'
                                                : rowData?.month == 6
                                                ? 'June'
                                                : rowData?.month == 7
                                                ? 'July'
                                                : rowData?.month == 8
                                                ? 'August'
                                                : rowData?.month == 9
                                                ? 'September'
                                                : rowData?.month == 10
                                                ? 'October'
                                                : rowData?.month == 11
                                                ? 'November'
                                                : rowData?.month == 12
                                                ? 'December'
                                                : ''}
                                        </td>
                                        <td>{rowData?.year}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Tabs
                            activeKey={page}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            // eslint-disable-next-line radix
                            onSelect={(k) => setPage(parseInt(k))}
                        >
                            {tableDataDisplay()}
                        </Tabs>
                    </Row>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className="btnInfo me-2 ms-2"
                            variant="primary"
                            style={{ minWidth: '6rem' }}
                            disabled={page === 1}
                            onClick={() => {
                                setPage((currPage) => currPage - 1);
                            }}
                        >
                            Prev
                        </Button>
                        <Button
                            type="button"
                            className={`btnSuccess ${
                                rowData.status === 'approved' && page === pageLength && 'd-none'
                            }`}
                            disabled={page === pageLength}
                            variant="success"
                            style={{ minWidth: '6rem' }}
                            onClick={() => setPage((currPage) => currPage + 1)}
                        >
                            Next
                        </Button>
                        {rowData.status === 'approved' && (
                            <Button
                                onClick={submitHandler}
                                className={`btnSuccess ${page !== pageLength && 'd-none'}`}
                                type="button"
                                variant="success"
                                style={{ minWidth: '6rem' }}
                                disabled={isSubmitted}
                            >
                                Submit
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

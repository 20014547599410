/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Purchase Order DataTable
 * Description: Purchase Order DataTable
 * Date: 26/04/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { usePurchaseOrder } from './ContextAPI';

export default function DataTable() {
    const { dispatchs, getAllData, setShowModal, setRowData } = usePurchaseOrder();
    const axiosPrivate = useAxiosPrivate();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const viewHander = async (row) => {
        try {
            const response = await axiosPrivate.post(
                UrlService.getPurchaseOrderDetailsUrl(),
                JSON.stringify({ p_id: row.id }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setRowData(response?.data?.request_data);
            setShowModal(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const columns = [
        {
            name: 'PO No.',
            selector: (row) => row.purshase_order_no,
            center: true,
            sortable: true,
        },
        {
            name: 'Supplier Name',
            selector: (row) => row.supplier_name,
            center: true,
            sortable: true,
        },
        {
            name: 'PO Data',
            selector: (row) =>
                row.purshase_order_date
                    ? moment(row.purshase_order_date).format('DD MMM YYYY')
                    : '',
            center: true,
            sortable: true,
        },
        {
            name: 'Supplied Data',
            selector: (row) =>
                row.purchase_order_supplied_date
                    ? moment(row.purchase_order_supplied_date).format('DD MMM YYYY')
                    : '',
            center: true,
            sortable: true,
        },
        {
            name: 'Delivery Location',
            selector: (row) => row.warehouse_name,
            center: true,
            sortable: true,
        },
        {
            name: 'Delivery Date',
            selector: (row) =>
                row.expected_delivery_date
                    ? moment(row.expected_delivery_date).format('DD MMM YYYY')
                    : '',
            center: true,
            sortable: true,
        },
        // {
        //     name: 'Status',
        //     selector: (row) => row.purchase_order_status,
        //     cell: (row) =>
        //         row.purchase_order_status == '2' ? (
        //             <Badge className="badgeInactive" bg="success">
        //                 Pending
        //             </Badge>
        //         ) : row.purchase_order_status == '3' ? (
        //             <Badge className="badgeActive" bg="success">
        //                 Supplied
        //             </Badge>
        //         ) : (
        //             <Badge className="badgeInactive" bg="success">
        //                 Generated
        //             </Badge>
        //         ),
        // },
        {
            name: 'Action',
            selector: (row) => row.action,
            center: true,
            cell: (row) => (
                <Button
                    className="btn btn-light btnSuccess"
                    variant="primary"
                    onClick={() => viewHander(row)}
                >
                    View
                </Button>
            ),
        },
    ];

    const search_column = {
        partner: 'Partner',
        facility: 'Facility',
        dispensary: 'dispensary',
        qty: 'Quantity',
        date: 'Date',
    };
    return useDataTable({ columns, data: dispatchs, search_column });
}

/* eslint-disable eqeqeq */
/*
 * Author M. Atoar Rahman
 * Title: Current Stock Entry
 * Description: Index for Current Stock Entry
 * Date: 30/04/2022
 */

import { Button, Card } from 'react-bootstrap';
// import { useAuth } from '../../../contexts/AuthContext';
import { useCurrentStockEntry } from './ContextAPI';
import DataTable from './DataTable';
import FormIndex from './FormIndex';
import FormInfo from './FormInfo';

export default function Index() {
    const { show, setShow, rowData, setRowData, stockData, setIsSubmitted } =
        useCurrentStockEntry();
    // const { auth } = useAuth();
    // const layer = auth?.employee_info?.layer_id;
    const createHander = () => {
        setShow(true);
        setRowData('');
        setIsSubmitted(false);
    };

    const backHander = () => {
        setShow(false);
        setRowData('');
    };

    return (
        <>
            <Card style={{ position: 'relative', zIndex: '10' }}>
                <Card.Header className="cardHeader">
                    <h3>Current Stock Entry</h3>
                    {show ? (
                        <div>
                            <Button
                                onClick={() => backHander()}
                                className="btnInfo"
                                variant="primary"
                            >
                                Back to List
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Button
                                onClick={() => createHander()}
                                className="btnInfo"
                                variant="primary"
                            >
                                Create New
                            </Button>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>{show ? <FormInfo editData={rowData} /> : <FormIndex />}</Card.Body>
            </Card>
            {!show && stockData?.length > 0 && (
                <Card className="mt-3">
                    <Card.Body>
                        <DataTable />
                    </Card.Body>
                </Card>
            )}
        </>
    );
}

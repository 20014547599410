/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: D1 Form: Medicines Requisition for Facility
 * Description: Master Setup of D1 Form: Medicines Requisition for Facility Form: Create and Update
 * Date: 15/03/2022
 */

import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import CommonApiService from '../../../services/CommonApiService';
import { useD1Form } from './ContextAPI';
import RequisitionTable from './RequisitionTable';

export default function FormInfo({ editData = null }) {
    // Dependancy
    const { drugCategory, getDrugCategory } = CommonApiService();
    const { addD1Form, editD1Form, requisitionList, setShow } = useD1Form();

    // Local State
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                month: editData && new Date(editData.month),
                year: editData && new Date(editData.year),
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            month: editData && new Date(editData.month),
            year: editData && new Date(editData.year),
        });
    }, [editData]);

    // Set default values
    useEffect(() => {
        if (!isEmpty(requisitionList)) {
            setValue('requisition', requisitionList);
        }
    }, [requisitionList]);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    const handleClose = () => {
        setShow(false);
    };

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);
        const formData = {
            ...data,
            month: data.month.getMonth() + 1,
            year: data.year.getFullYear(),
        };

        if (editData) {
            editD1Form(formData, editData.id); // Update Data
        } else {
            addD1Form(formData); // Create Data
        }
        reset();
    };

    const tableDataDisplay = useCallback(
        () =>
            drugCategory?.map((item, index) => (
                <Tab key={item.id} eventKey={index} title={item.name}>
                    <RequisitionTable id={item.id} />
                </Tab>
            )),
        [drugCategory]
    );

    register('requisition', { required: 'Requisition quantity is required!' });

    return (
        <Form>
            <fieldset className="customFieldset p-4 pb-2 mb-3">
                <Row>
                    <Col lg={6}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="formGrnStockID">
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>
                                                Month<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Controller
                                                control={control}
                                                name="month"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        selected={field.value}
                                                        placeholderText="Select month"
                                                        dateFormat="MM"
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                        onChange={(date) => field.onChange(date)}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        // minDate={
                                                        //     new Date(
                                                        //         new Date().getFullYear(),
                                                        //         new Date().getMonth() - 1,
                                                        //         new Date().getDate()
                                                        //     )
                                                        // }
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Month is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.month && (
                                                <span className="invalid">
                                                    {errors.month?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="formGrnStockID">
                                    <Row className="align-items-center">
                                        <Col lg={4}>
                                            <Form.Label>
                                                Year<span className="invalid">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Controller
                                                control={control}
                                                name="year"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        selected={field.value}
                                                        dateFormat="yyyy"
                                                        showYearPicker
                                                        placeholderText="Select year"
                                                        onChange={(date) => field.onChange(date)}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        minDate={
                                                            new Date(
                                                                new Date().getFullYear() - 1,
                                                                new Date().getMonth(),
                                                                new Date().getDate()
                                                            )
                                                        }
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Year is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors.year && (
                                                <span className="invalid">
                                                    {errors.year?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </fieldset>

            {errors.requisition && <span className="invalid">{errors.requisition?.message}</span>}

            <Tabs
                activeKey={page}
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
                // eslint-disable-next-line radix
                onSelect={(k) => setPage(parseInt(k))}
            >
                {tableDataDisplay()}
            </Tabs>

            <div className="formFooter">
                <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    className="btnInfo me-2 ms-2"
                    variant="primary"
                    style={{ minWidth: '6rem' }}
                    disabled={page === 1}
                    onClick={() => {
                        setPage((currPage) => currPage - 1);
                    }}
                >
                    Prev
                </Button>
                <Button
                    type="button"
                    className={`btnSuccess ${page === pageLength && 'd-none'}`}
                    variant="success"
                    style={{ minWidth: '6rem' }}
                    onClick={() => setPage((currPage) => currPage + 1)}
                >
                    Next
                </Button>

                <Button
                    onClick={handleSubmit(formSubmitHandler)}
                    className={`btnSuccess ${page !== pageLength && 'd-none'}`}
                    type="button"
                    variant="success"
                    style={{ minWidth: '6rem' }}
                    disabled={isSubmitted}
                >
                    Submit
                </Button>
            </div>
        </Form>
    );
}
